import React, { useState } from 'react';

import { View, Text, TextInput, SafeAreaView, Button } from 'react-native';

import { styles, colours } from '../styles/main';

import { FadeInView } from '../components/FadeIn';

export const ContactScreen = ({ navigation, route }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [hasErrors, setHasErrors] = useState(false);

  const [success, setSuccess] = useState('');

  const send = async (data) => {
    const url = `http://127.0.0.1:3000/api/email/send-email?name=${
      data.name
    }&email=${data.email}&message=${encodeURI(data.message)}`;
    return fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setSuccess(json.result.message);
        return json;
      })
      .catch((error) => {
        debugger;
        console.error(error);
      });
  };

  const submitEmail = async () => {
    console.log('submitEmail');

    let errors = 0;

    setHasErrors(false);

    if (!name) {
      errors = errors + 1;
      setNameError('Enter your name');
    }

    if (!email) {
      errors = errors + 1;
      setEmailError('Enter your email');
    }

    if (!message) {
      errors = errors + 1;
      setMessageError('Enter your message');
    }

    if (errors > 0) {
      return setHasErrors(true);
    }

    const newFormData = {
      name: name || 'Joe Bloggs',
      email: email || 'joebloggs@test.com',
      message: message || `test messagekslaslksla`,
    };

    await send({ ...newFormData });
  };

  return (
    <FadeInView>
      <View style={styles.container}>
        <SafeAreaView>
          {!!hasErrors && (
            <View>
              <Text style={styles.error}>You have some errors, please fix</Text>
            </View>
          )}
          {!!success && (
            <View>
              <Text style={styles.error}>{success}</Text>
            </View>
          )}
          {!success && (
            <SafeAreaView>
              <TextInput
                label='Name'
                value={name}
                placeholder='Name'
                style={styles.input}
                onChangeText={(value) => setName(value)}
              />
              <TextInput
                label='Email'
                inputMode='email'
                value={email}
                placeholder='Email'
                style={styles.input}
                onChangeText={(value) => setEmail(value)}
              />
              <TextInput
                label='Message'
                value={message}
                style={styles.textarea}
                onChangeText={(value) => setMessage(value)}
                placeholder='Message'
                multiline
              />
              <Button
                title='Submit'
                type='submit'
                color={colours.tertiary}
                onPress={() => submitEmail()}
              />
            </SafeAreaView>
          )}
        </SafeAreaView>
      </View>
    </FadeInView>
  );
};
