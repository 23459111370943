import { StyleSheet } from 'react-native';

export const colours = {
  one: '#22d48f',
  two: '#05386B',
  three: '#008a79',
  white: '#FFF',
  black: '#000',
  yellow: '#F9F871',
  darkerPurple: '#745EA1',
  darkPurple: '#A471B7',
  purple: '#D585CA',
  lightPurple: '#FF9BD8',
  green: '#84DE7E',
  cream: '#FFEECB',
  lightBlue: '#E3F0FF',
  darkRed: '#661D30',
  red: '#9C4E5E',
  turquoise: '#00C6BD',
  beige: '#D7A31A',
  brown: '#877555',
  grey: '#616161',
  lightGrey: '#bdbdbd',
  mediumGrey: '#717788',
  lighterGrey: '#F1F1E6',
  primary: '#22d48f',
  secondary: '#05386B',
  tertiary: '#008a79',
  transparent: 'transparent'
}

export const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 24,
    backgroundColor: '#eaeaea',
    height: '100%'
  },
  resultsContainer: {
    paddingTop: 10,
    paddingBottom: 20,
    textAlign: 'center',
    backgroundColor: colours.tertiary
  },
  pageContainer: {
    marginBottom: 'auto',
    padding: 16
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
  imageAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  error: {
    color: '#ff5722',
    marginBottom: 20
  },
  view: {
    paddingTop: 12,
    marginBottom: 'auto'
  },
  input: {
    height: 40,
    marginBottom: 20,
    backgroundColor: colours.white,
    borderWidth: 1,
    borderColor: colours.lightGrey,
    padding: 10,
  },
  textarea: {
    height: 120,
    marginBottom: 20,
    backgroundColor: colours.white,
    borderWidth: 1,
    borderColor: colours.lightGrey,
    padding: 10,
  },
  title: {
    marginTop: 16,
    paddingVertical: 8,
    borderWidth: 4,
    borderColor: '#20232a',
    borderRadius: 6,
    backgroundColor: '#61dafb',
    color: '#20232a',
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 'bold',
  },
  body1: {
    fontSize: 16
  },
  pageParagraph: {
    fontSize: 16,
    marginBottom: 20
  },
  video: {
    aspectRatio: 16 / 9,
    width: '100%',
    marginBottom: 20
  },
  quoteShare: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  }
});
