import React, { useCallback, useState } from 'react';

import {
  View,
  Text,
  Alert,
  ScrollView,
  Platform,
  ImageBackground,
  StyleSheet,
} from 'react-native';

import { WebView } from 'react-native-webview';

import { FadeInView } from '../components/FadeIn';

export const GratitudeWallScreen = ({ navigation, route }) => {
  const { type, title } = route.params;

  const [playing, setPlaying] = useState(false);

  const onStateChange = useCallback((state) => {
    if (state === 'ended') {
      setPlaying(false);
      Alert.alert('video has finished playing!');
    }
  }, []);

  const togglePlaying = useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  const styles = StyleSheet.create({
    image: {
      flex: 1,
      justifyContent: 'center',
      height: '100%',
    },
  });

  return (
    <FadeInView style={{ flex: 1}}>
      <ImageBackground
        source={require('../assets/backgrounds/michael-dziedzic-3s1DBnKShiY-unsplash.jpg')}
        resizeMode='cover'
        style={styles.image}
      >
        <ScrollView>

        </ScrollView>
      </ImageBackground>
    </FadeInView>
  );
};
