import { Platform } from 'react-native';

export const renderCreditText = (context, canvasWidth, canvasHeight, text) => {
  return new Promise((resolve) => {
    context.fillStyle = '#FFF';
    context.font = `16px monospace`;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    resolve(
      context.fillText(text.toUpperCase(), canvasWidth / 2, canvasHeight - 50)
    );
  });
};

export const getTextLines = async (
  context,
  canvasWidth,
  text,
  fontFamily,
  fontSize,
  padding = 50
) => {
  // Break text into lines if it overflows the canvas
  const words = text.split(' ');
  let line = '';
  const lines = [];
  for (let i = 0; i < words.length; i++) {
    const testLine = line + words[i] + ' ';
    console.log('testLine', testLine);
    context.font = `${fontSize}px ${fontFamily}`;
    console.log('fontSize', fontSize);
    const lineWidth = (await context.measureText(testLine)).width;
    console.log('line width', lineWidth);
    if (lineWidth > canvasWidth - 2 * padding && i > 0) {
      lines.push(line);
      line = words[i] + ' ';
    } else {
      line = testLine;
    }
  }
  lines.push(line);

  return Promise.resolve(lines);
};

export const getFontSize = async (context, canvasWidth, fontFamily, text) => {
  // Autoscale text
  let fontSize = 30; // Initial font size

  const maxWidth = canvasWidth;
  const minFontSize = 56;

  do {
    context.font = `${fontSize}px ${fontFamily}`;
    // context.clearRect(0, 0, canvas.width, canvas.height);
    fontSize = fontSize - 1;
  } while (
    context.measureText(text).width > maxWidth &&
    fontSize >= minFontSize
  );

  // Draw the resized text on the canvas
  // context.fillText(text, 0, 50);

  console.log('fontSize', fontSize);
  console.log('maxWidth', maxWidth);
  console.log('minFontSize', minFontSize);
  console.log(
    'context.measureText(text).width',
    context.measureText(text).width
  );

  return Promise.resolve(fontSize);
};

// context.fillStyle = '#282828';
// context.fillRect(0, 0, canvasWidth, canvasHeight);
// ctx, text, x, y, width, height, fontSize

export const drawTextInBox = (ctx, txt, font, x, y, w, h, angle) => {
  angle = angle || 0;
  var fontHeight = 20;
  var hMargin = 4;
  ctx.font = fontHeight + 'px ' + font;
  ctx.textAlign = 'left';
  ctx.textBaseline = 'top';
  ctx.fillStyle = 'white';
  var txtWidth = ctx.measureText(txt).width + 2 * hMargin;
  ctx.save();
  ctx.translate(x + w / 2, y);
  ctx.rotate(angle);
  ctx.strokeRect(-w / 2, 0, w, h);
  ctx.scale(w / txtWidth, h / fontHeight);
  ctx.translate(hMargin, 0);
  ctx.fillText(txt, -txtWidth / 2, 0);
  ctx.restore();
};

export const fitText = (
  ctx,
  text,
  x,
  y,
  width,
  height,
  fontSize,
  fontFamily,
  isTesting
) => {
  /* testing purposes */
  if (isTesting) {
    ctx.fillStyle = 'red';
    ctx.fillRect(x, y, width, height);
  }

  ctx.fillStyle = 'white';
  // ctx.textBaseline = 'bottom';

  ctx.font = `normal ${fontSize}px ${fontFamily}`;
  var metrics = ctx.measureText(text);

  if (metrics.width <= width) {
    ctx.fillText(text, x, y + height - fontSize / 4);
    return;
  }

  // Wrap text
  var words = text.split(' '),
    line = '',
    lines = [];

  for (var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + ' ';
    metrics = ctx.measureText(testLine);
    if (metrics.width > width && n > 0) {
      lines.push(line);
      line = words[n] + ' '; // next line
    } else {
      line = testLine;
    }
  }
  lines.push(line);
  if (lines.length > 3) {
    console.log('fontSize', fontSize);
    return fitText(
      ctx,
      text,
      x,
      y,
      width,
      height,
      fontSize - 1,
      fontFamily,
      isTesting
    );
  }

  var line_y = y + height - fontSize / 4;
  for (var i = lines.length - 1; i >= 0; i--) {
    ctx.fillText(lines[i], x, line_y);
    line_y -= fontSize * 1.1;
  }
};

export const addText = (ctx, text, width, height, x, y) => {
  let addBreakArray = [];
  let wordsArray = [];
  // let width = canvasWidth / 2;
  let fontsize;
  let textHeight;

  fontsize = width / 2;
  ctx.fillStyle = 'grey';
  ctx.fillRect(x, y, width, height);
  wordsArray = text.split(' ');
  measureWidth();

  function measureWidth() {
    var accumulator = 0;
    ctx.font = fontsize + 'px Arial';
    for (let i = 0; i < wordsArray.length; i++) {
      var wordWidth = ctx.measureText(wordsArray[i] + ' ').width;
      while (wordWidth > width) {
        fontsize = fontsize * 0.9;
        ctx.font = fontsize + 'px Arial';
        wordWidth = ctx.measureText(wordsArray[i] + ' ').width;
      }
      if (accumulator + wordWidth > width) {
        addBreakArray.push('~');
        textHeight += fontsize;
        accumulator = wordWidth;
      } else {
        accumulator += wordWidth;
      }
      addBreakArray.push(wordsArray[i]);
    }
    addBreakArray.push('~');
    textHeight += fontsize;
    measureHeight();
  }

  function measureHeight() {
    if (textHeight > height * 0.75) {
      fontsize = fontsize * 0.9;
      textHeight = 0;
      addBreakArray = [];
      measureWidth();
    } else {
      renderText();
    }
  }

  function renderText() {
    var currentY = y + fontsize;
    ctx.fillStyle = 'red';
    ctx.textBaseline = 'bottom';
    var lineArray = [];
    for (let j = 0; j < addBreakArray.length; j++) {
      if (addBreakArray[j] === '~') {
        ctx.fillText(lineArray.join(' '), x, currentY);
        currentY += fontsize;
        lineArray = [];
      } else {
        lineArray.push(addBreakArray[j]);
      }
    }
  }
};

/*
export const updateCanvasText = (ctx, text, width, height) => {
  const updatedText = text.trim();
  const shortText = updatedText[0] + updatedText[text.length - 1];
  const txtSize = measureText({
    font: 'arial',
    text: text.length > 1 ? shortText : text,
  });
  if (txtSize) {
    ctx.clearRect(0, 0, width, height);
    ctx.font = txtSize.font;
    const width = ctx.measureText(text).width;
    const actualWidth = width - txtSize.left - txtSize.rightOffset;
    const scale = (width - 20) / actualWidth;
    console.clear();
    if (txtSize.baseSize * scale > height) {
      console.log('Font scale too large to fit vertically');
    } else if (scale > 1) {
      console.log('Scaled > 1, can result in loss of precision ');
    }
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#000';
    ctx.textAlign = 'left';
    ctx.setTransform(scale, 0, 0, scale, 10 - txtSize.left * scale, 0);
    ctx.fillText(text, 0, 0);
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.fillStyle = '#CCC8';
    ctx.fillRect(0, 0, 10, height);
    ctx.fillRect(width - 10, 0, 10, height);
  } else {
    console.clear();
    console.log('Empty string ignored');
  }
};
const measureText = (() => {
  var data,
    w,
    size = 120;
  const isColumnEmpty = (x) => {
    var idx = x,
      h = size * 2;
    while (h--) {
      if (data[idx]) {
        return false;
      }
      idx += can.width;
    }
    return true;
  };
  const can = document.createElement('canvas');
  const ctx = can.getContext('2d');
  return ({ text, font, baseSize = size }) => {
    size = baseSize;
    can.height = size * 2;
    font = size + 'px ' + font;
    if (text.trim() === '') {
      return;
    }
    ctx.font = font;
    can.width = (w = ctx.measureText(text).width) + 8;
    ctx.font = font;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'left';
    ctx.fillText(text, 0, size);
    data = new Uint32Array(
      ctx.getImageData(0, 0, can.width, can.height).data.buffer
    );
    var left, right;
    var lIdx = 0,
      rIdx = can.width - 1;
    while (lIdx < rIdx) {
      if (left === undefined && !isColumnEmpty(lIdx)) {
        left = lIdx;
      }
      if (right === undefined && !isColumnEmpty(rIdx)) {
        right = rIdx;
      }
      if (right !== undefined && left !== undefined) {
        break;
      }
      lIdx += 1;
      rIdx -= 1;
    }
    data = undefined; // release RAM held
    can.width = 1; // release RAM held
    return right - left >= 1
      ? {
          left,
          right,
          rightOffset: w - right,
          width: right - left,
          measuredWidth: w,
          font,
          baseSize,
        }
      : undefined;
  };
})();

*/

export const fillFittedText = async (
  ctx,
  text = '',
  x = 0,
  y = 0,
  target_width = ctx.canvas.width,
  font_family = 'Arial'
) => {
  return new Promise(async (resolve) => {
    ctx.save();
    let font_size = 32;
    const updateFont = () => {
      ctx.font = font_size + 'px ' + font_family;
    };
    const getBBOxWidth = async (text) => {
      return (await ctx.measureText(text)).width;
    };
    updateFont();
    let width = await getBBOxWidth(text);
    // first pass width increment = 1
    while (width && width <= target_width) {
      font_size++;
      updateFont();
      width = await getBBOxWidth(text);
    }
    // second pass, the other way around, with increment = -0.1
    while (width && width > target_width) {
      font_size -= 0.1;
      updateFont();
      width = await getBBOxWidth(text);
    }
    console.log('getBBOxWidth width', width);
    // revert to last valid step
    font_size += 0.1;
    updateFont();

    // we need to measure where our bounding box actually starts
    const offset_left = ctx.measureText(text).actualBoundingBoxLeft || 0;

    ctx.textAlign = Platform.OS === 'web' ? 'center' : 'left';
    ctx.fillStyle = '#FFF';
    ctx.fillText(text, x + offset_left, y);
    ctx.save();
    resolve();
  });
};

export const wrapText = async (
  context,
  text,
  x,
  y,
  maxWidth,
  lineHeight,
  fontFamily
) => {
  return new Promise(async (resolve) => {
    context.save();
    context.font = `15pt ${fontFamily}`;
    context.fillStyle = '#FFF';
    context.textAlign = 'left';
    context.textBaseline = 'top';

    const words = text.split(' ');
    let line = '';
    let n = 0;

    const drawText = async () => {
      const testLine = line + words[n] + ' ';
      const testWidth = (await context.measureText(testLine)).width;

      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }

      n++;

      if (n < words.length) {
        await drawText();
      } else {
        context.fillText(line, x, y);
        context.restore();
        resolve();
      }
    };

    await drawText();
  });
};
