import React from 'react';

import { Text, View, StyleSheet } from 'react-native';

import { colours } from '../styles/main';

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    padding: 24,
    backgroundColor: colours.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 16
  },
  buttonText: {
    color: colours.white,
    fontSize: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginLeft: 16
  },
});

export const CtaButton = (props) => {
  return (
    <View style={styles.button}>
      { props.icon && props.icon }
      <Text style={styles.buttonText}>{props.children}</Text>
    </View>
  );
};
