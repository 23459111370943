import React, { useEffect, useRef } from 'react';

import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import {
  requestPermissionsAsync,
  createAssetAsync,
  saveToLibraryAsync,
} from 'expo-media-library';

import Ionicons from '@expo/vector-icons/Ionicons';

import { StyleSheet, View, Pressable, Platform } from 'react-native';

import { colours } from '../styles/main';

import { FadeInView } from '../components/FadeIn';
import QuoteCanvasCard from '../components/QuoteCanvasCard';
import { CtaButton } from '../components/CtaButton';

import SOBRIETY_CHIPS_DATA from '../data/sobriety-chips.json';
import BACKGROUNDS from '../data/earthy-colours-rgb.json';

export const SobrietySingleQuoteScreen = ({ navigation, route }) => {
  const { id, quote, author } = route.params;
  const childRef = useRef();

  /* 
  const CHIP_DATA_INDEX = SOBRIETY_CHIPS_DATA.findIndex(
    (chip) => chip.order === id
  );
  const chip = {
    ...SOBRIETY_CHIPS_DATA[CHIP_DATA_INDEX],
    backgroundColor: `rgb(${BACKGROUNDS.colors[CHIP_DATA_INDEX].rgb})`,
    single: true,
  }; */

  const styles = StyleSheet.create({
    container: {
      // backgroundColor: chip.backgroundColor,
      width: '100%',
      height: '100%',
    },
    quoteShare: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },
  });

  useEffect(() => {
    navigation.setOptions({
      title: author,
    });
  }, [author]);

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const shareOnWeb = async () => {
    let base64String;

    try {
      base64String = await childRef.current.getSvgBase64();
      console.log('base64String', base64String);
    } catch (error) {
      console.log('base64String error', error);
    }

    // Create a Blob from the PNG data
    // const byteString = atob(pngDataUrl.split(',')[1]);
    // const mimeString = pngDataUrl.split(',')[0].split(':')[1].split(';')[0];

    /* 
    var ab = new ArrayBuffer(base64String.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < base64String.length; i++) {
      ia[i] = base64String.charCodeAt(i);
    } */

    const blob = b64toBlob(base64String);

    console.log('blob', blob);

    // Create a File object from the Blob
    const file = new File([blob], 'file.png', { type: 'image/png' });

    // Use the Web Share API to share the PNG file
    if (navigator.share) {
      await navigator.share({
        files: [file],
        text: 'Gratitude Quotes:',
        url: 'https://gratefultoday.com',
      });
    } else {
      console.log('Web Share API not supported');
    }
  };

  const share = async (expo) => {
    if (Platform.OS === 'web') {
      return shareOnWeb();
    }

    let base64Data;

    console.log('sasasasasasas');

    try {
      base64Data = await childRef.current.getSvgBase64();
      console.log('base64Data', base64Data);
    } catch (error) {
      console.log('base64Data error', error);
    }

    console.log('12212121');

    const filename = FileSystem.cacheDirectory + `quote.png`;

    await FileSystem.writeAsStringAsync(filename, base64Data, {
      encoding: FileSystem.EncodingType.Base64,
    });

    let fileUri;

    console.log('filename', filename);

    /* 
    causes "could not create asset error"
    try {
      const permission = await requestPermissionsAsync();
      if (permission.status === 'granted') {
        fileUri = await saveToLibraryAsync(filename);
      }
    } catch (error) {
      console.error(error);
    }
    */

    try {
      if (expo) {
        // no Mail app shown for iOS
        console.log('fileUri', filename);
        await Sharing.shareAsync(filename);
      } else {
        // Mail app shows
        await Share.share({
          url: fileUri, // iOS only
        });
      }
    } catch (err) {
      console.log('Failed to share', err);
    }
  };

  return (
    <FadeInView style={styles.container}>
      <QuoteCanvasCard ref={childRef} quote={quote} author={author} />
      <Pressable style={styles.quoteShare} onPress={() => share(true)}>
        <CtaButton
          icon={
            <Ionicons name='md-share-social' size={32} color={colours.white} />
          }
        >
          Share Quote
        </CtaButton>
      </Pressable>
    </FadeInView>
  );
};
