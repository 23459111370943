import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useState,
} from 'react';

import {
  Text,
  View,
  StyleSheet,
  TouchableHighlight,
  Image,
  Share,
  Platform,
  SafeAreaView,
  Dimensions,
  Pressable,
  Image as RNimage,
} from 'react-native';

import { colours, styles } from '../styles/main';

import Ionicons from '@expo/vector-icons/Ionicons';

import { StatusBar } from 'expo-status-bar';

import { Loader } from './Loader';

import Canvas, { Image as CanvasImage } from 'react-native-canvas';

import { PRIMARY_QUOTE_FONT, SECONDARY_QUOTE_FONT } from '../config/config';
import { getTextLines, getFontSize, renderCreditText, wrapText, fillFittedText } from '../utils/canvas';
import { handleShare } from '../utils/share';
import { DARK_FLOWERS } from '../utils/base64-images';
import { CtaButton } from '../components/CtaButton';

const text = 'This is a inspirational quote';
const author = 'John Doe';
const minFontSize = 30;
const maxFontSize = 60;
const padding = 50;
const lineHeight = 2;
const align = 'left';
const valign = 'center';
// const canvasWidth = 800;
// const canvasHeight = 600;
const backgroundColor = 'transparent';
const textColor = '#FFF';
const authorColor = '#FFF';
let authorFontSize = 32;
// supported fonts on android: https://www.skcript.com/svr/react-native-fonts
const fontFamily = PRIMARY_QUOTE_FONT;
const authorFontFamily = SECONDARY_QUOTE_FONT;
const searchPhrase = 'test';

const ThankYouCanvasCard = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true);

  const canvasRef = useRef(null);

  const renderTitleText = async (
    context,
    canvasWidth,
    canvasHeight,
    fontSize,
    lines
  ) => {
    /* 
    context.fillStyle = '#FFF';
    context.font = `36px ${PRIMARY_QUOTE_FONT}`;
    context.textAlign = 'left';
    context.textBaseline = 'top';
    context.fillText(text, canvasWidth / 2 - 50, 60); */

    let yAuthorPos = 0;

    // const valign = 'middle'

    // Calculate text height
    // const textHeight = lines.length * fontSize * lineHeight + padding * 2;

    // Draw Title on canvas
    context.fillStyle = textColor;
    context.textBaseline = 'top';
    const topOffset = 0;
    for (let i = 0; i < lines.length; i++) {
      let yPos;
      if (lines.length === 1) {
        // If there's only one line of text, center it vertically
        yPos = padding + topOffset;
      } else {
        // Otherwise, use the regular calculation
        yPos = i * (fontSize * lineHeight) + padding + topOffset;
      }

      // Calculate x position based on alignment
      //const xPos = padding;

      const lineWidth = (await context.measureText(lines[i])).width;
      const xPos = canvasWidth / 2 - lineWidth / 2 - padding / 2;

      yAuthorPos = yPos + fontSize * lineHeight;

      context.fillText(lines[i], xPos, yPos);
    }
  };

  const renderMessageText = async (
    context,
    canvasWidth,
    canvasHeight,
    fontSize,
    lines
  ) => {
    let yAuthorPos = 0;

    const valign = 'middle';

    // Calculate text height
    const textHeight = lines.length * fontSize * lineHeight + padding * 2;

    // Draw Message on canvas
    context.fillStyle = textColor;
    context.textBaseline = 'top';
    const topOffset = 0;
    for (let i = 0; i < lines.length; i++) {
      let yPos;
      if (lines.length === 1) {
        // If there's only one line of text, center it vertically
        if (valign === 'middle') {
          yPos = canvasHeight / 2 - fontSize / 2;
        } else {
          yPos = padding + topOffset;
        }
      } else {
        // Otherwise, use the regular calculation
        if (valign === 'middle') {
          yPos =
            canvasHeight / 2 -
            textHeight / 2 +
            (i * (fontSize * lineHeight) + padding);
        } else {
          yPos = i * (fontSize * lineHeight) + padding + topOffset;
        }
      }

      // Calculate x position based on alignment
      let xPos;
      if (align === 'left') {
        xPos = padding;
      } else if (align === 'center') {
        const lineWidth = (await context.measureText(lines[i])).width;
        xPos = canvasWidth / 2 - lineWidth / 2;
      }

      yAuthorPos = yPos + fontSize * lineHeight;

      context.fillText(lines[i], xPos, yPos);
    }
  };

  const renderBackground = async (context, canvasWidth, canvasHeight) => {
    console.log('renderBackground');

    return new Promise(resolve => {
      const imageUri = DARK_FLOWERS;
      const bgWidth = 103;
      const bgHeight = 200;
  
      // render background colour
      context.fillStyle = '#282828';
      context.fillRect(0, 0, canvasWidth, canvasHeight);
  
      // render background image
      let image;

      if (Platform.OS === 'web') {
        image = document.createElement('img');
      }

      if (Platform.OS !== 'web') {
        image = new CanvasImage(canvasRef.current);
      }

      image.src = imageUri; 
  
      image.addEventListener('load', () => {
        context.drawImage(
          image,
          canvasWidth - bgWidth,
          -(bgHeight * 0.25),
          bgWidth,
          bgHeight
        );
        context.save();
        context.translate(50, 50);
        // context.rotate(0.45);
        // context.translate(-10,-10);
        context.scale(1, -1);
        context.drawImage(
          image,
          -bgWidth / 2,
          -(canvasHeight - bgHeight),
          bgWidth,
          bgHeight * -1
        );
        resolve(context.restore());
      });
    })

  };

  const renderCanvas = async (customWidth, customHeight) => {
    const canvas = canvasRef.current;

    if (canvas === null) {
      return;
    }

    const context = canvas.getContext('2d');

    const { width, height } = Dimensions.get('window');

    const canvasWidth = width || customWidth;
    const canvasHeight = height || customHeight;

    // Set canvas dimensions
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    await renderBackground(context, canvasWidth, canvasHeight);

    /* 
    const titleFontSize = await getFontSize(context, canvasWidth, PRIMARY_QUOTE_FONT, props.title, 0);
    const titleLines = await getTextLines(context, canvasWidth, props.title, PRIMARY_QUOTE_FONT, titleFontSize);

    renderTitleText(
      context,
      canvasWidth,
      canvasHeight,
      titleFontSize,
      titleLines
    ); */

      /* 
    const messageFontSize = await getFontSize(
      context,
      canvasWidth,
      PRIMARY_QUOTE_FONT,
      props.message
    );
    const messageLines = await getTextLines(
      context,
      canvasWidth,
      props.message,
      messageFontSize
    );
    */
    await renderCreditText(context, canvasWidth, canvasHeight, 'gratefultoday.com');
    await fillFittedText(context, props.title, 30, 100, canvasWidth - 100, fontFamily);
    await wrapText(context, props.message, 30, 200, canvasWidth - 100, 32, PRIMARY_QUOTE_FONT);

    // ctx, text = "", x = 0, y = 0, target_width = ctx.canvas.width, font_family = "Arial" 
    // await fillFittedText(context, props.title, 30, 100, canvasWidth - 100, fontFamily);

    // updateCanvasText(context, props.title, canvasWidth, 100)

    // ctx, text, width, height, x, y
    // addText(context, props.title, canvasWidth - 100, 100, 30, 30);

    // addText(context, props.message, canvasWidth - 60, 300, 30, 200);
    /* 
    drawTextInBox(
      context,
      props.title,
      PRIMARY_QUOTE_FONT,
      50,
      30,
      canvasWidth - 100,
      100
    ); */

    // ctx, text, x, y, width, height, fontSize, fontFamily, isTesting
    /* 
    fitText(
      context,
      props.message,
      50,
      100,
      canvasWidth - 100,
      200,
      60,
      PRIMARY_QUOTE_FONT,
      true
    ); */

    /* 
    renderMessageText(
      context,
      canvasWidth,
      canvasHeight,
      messageFontSize,
      messageLines
    ); */


    /* 
    const fontSize = await getFontSize(context, canvasWidth);
    const lines = await getTextLines(context, canvasWidth);
    
    // context.drawImage(image, 0, 0, canvasWidth, canvasHeight)

    await renderCanvasText(
      context,
      canvasWidth,
      canvasHeight,
      fontSize,
      lines
    );

    renderCreditText(
      context,
      canvasWidth,
      canvasHeight,
      'gratefultoday.com'
    ); */

    setLoading(false);
    return Promise.resolve();
  };

  useEffect(() => {
    renderCanvas();
  }, [
    props.title,
    props.message
  ]);

  /* 
  useImperativeHandle(ref, () => ({
    getSvgBase64: () => {
      return new Promise(async (resolve, reject) => {
        console.log('START 1');
        try {
          console.log('canvasRef.current', canvasRef.current);
          // canvasRef.current.width = 100;
          // canvasRef.current.height = 100;
          const base64 = await canvasRef.current.toDataURL();
          const base64String = base64.split(',')[1];
          resolve(base64String);
        } catch (error) {
          reject(error);
          console.error('useImperativeHandle getSvgBase64 error', error);
        }
      });
    },
  })); */

  return (
    <View style={{ width: '100%', height: '100%' }}>
      {loading && <Loader />}
      {Platform.OS !== 'web' && (
        <Canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: loading ? 0 : '100%',
            backgroundColor: 'transparent',
          }}
        />
      )}
      {Platform.OS === 'web' && (
        <canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: loading ? 0 : '100%',
            backgroundColor: 'transparent',
          }}
        />
      )}
      <Pressable style={styles.quoteShare} onPress={() => handleShare(true, canvasRef)}>
        <CtaButton
          icon={
            <Ionicons name='md-share-social' size={32} color={colours.white} />
          }
        >
          Share Card
        </CtaButton>
      </Pressable>
    </View>
  );
});

export default ThankYouCanvasCard;
