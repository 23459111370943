import * as React from 'react';
import {
  View,
  useWindowDimensions,
  ImageBackground,
  Button,
  Pressable,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';

import { QuoteCard } from '../components/QuoteCard';
import { TextCard } from '../components/TextCard';

import { styles, colours } from '../styles/main';

import { TAB_BAR_LABEL_FONT, TAB_VIEW_INDICATOR_STYLE, TAB_VIEW_STYLE, TAB_VIEW_PAGER_STYLE } from '../config/config';

const FEATURED_QUOTE = {
  id: 1,
  quote:
    'In daily life we must see that it is not happiness that makes us grateful, but gratefulness that makes us happy.',
  author: 'David Steindl-Rast',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
};

const GRATITUDE_LINK_ONE = {
  title: 'Quotes',
  subtext: 'View gratitude quotes',
  theme: 'tertiary',
};

const GRATITUDE_LINK_TWO = {
  title: 'Affirmations',
  subtext: 'View gratitude affirmations',
  theme: 'secondary',
};

const GRATITUDE_LINK_THREE = {
  title: 'Thank You Card',
  subtext: 'Show gratitude, say thank you',
  theme: 'tertiary',
};

const GRATITUDE_LINK_FOUR = {
  title: 'Gratitude Wall',
  subtext: 'Public gratitude wall',
  theme: 'secondary',
};

const SOBRIETY_LINK_ONE = {
  title: 'Sobriety Chips',
  subtext: 'View virtual sobriety chips',
  theme: 'tertiary',
};

const SOBRIETY_LINK_TWO = {
  title: 'Just For Today',
  subtext: 'A card by Alcoholics Anonymous',
  theme: 'secondary',
};

export const HomeScreen = ({ navigation, route }) => {
  const [index, setIndex] = React.useState(0);
  const layout = useWindowDimensions();

  const goToList = (type, title) => {
    navigation.navigate('List', {
      screen: 'List',
      initial: false,
      title,
      type,
    });
  };

  const GratitudeRoute = () => (
    <SafeAreaView style={{ flex: 1 }}>
      <ImageBackground
        source={require('../assets/backgrounds/jay-mantri-TFyi0QOx08c-unsplash.jpg')}
        resizeMode='cover'
        style={styles.image}
      >
        <ScrollView>
          <View style={styles.view}>
            <Pressable onPress={() => goToList('quote', 'Gratitude Quotes')}>
              <TextCard {...GRATITUDE_LINK_ONE} />
            </Pressable>
            <Pressable
              onPress={() => goToList('affirmation', 'Gratitude Affirmations')}
            >
              <TextCard {...GRATITUDE_LINK_TWO} />
            </Pressable>
            <Pressable
              onPress={() =>
                navigation.navigate('ThankYouCard', {
                  screen: 'ThankYouCard',
                  initial: false,
                })
              }
            >
              <TextCard {...GRATITUDE_LINK_THREE} />
            </Pressable>
            <Pressable
              onPress={() =>
                navigation.navigate('GratitudeWall', {
                  screen: 'GratitudeWall',
                  initial: false,
                })
              }
            >
              <TextCard {...GRATITUDE_LINK_FOUR} />
            </Pressable>
          </View>
        </ScrollView>
      </ImageBackground>
    </SafeAreaView>
  );

  const SecondRoute = () => (
    <View style={{ flex: 1, backgroundColor: '#673ab7' }}>
      <ImageBackground
        source={require('../assets/backgrounds/maxx-gong-AnKC9klnyKk-unsplash.jpg')}
        resizeMode='cover'
        style={styles.image}
      ></ImageBackground>
    </View>
  );

  const SobrietyRoute = () => (
    <View style={{ flex: 1, backgroundColor: '#673ab7' }}>
      <ImageBackground
        source={require('../assets/backgrounds/theodor-vasile-P3SWMc6wLOQ-unsplash.jpg')}
        resizeMode='cover'
        style={styles.image}
      >
        <Pressable
          onPress={() =>
            navigation.navigate('SobrietyChips', {
              screen: 'SobrietyChips',
              initial: false,
            })
          }
        >
          <TextCard {...SOBRIETY_LINK_ONE} />
        </Pressable>
        <Pressable
          onPress={() =>
            navigation.navigate('JustForToday', {
              screen: 'JustForToday',
              initial: false,
            })
          }
        >
          <TextCard {...SOBRIETY_LINK_TWO} />
        </Pressable>
      </ImageBackground>
    </View>
  );

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'gratitude':
        return <GratitudeRoute navigation={navigation} />;
      case 'second':
        return <SecondRoute />;
      case 'sobriety':
        return <SobrietyRoute />;
      default:
        return null;
    }
  };

  const [routes] = React.useState([
    { key: 'gratitude', title: 'Gratitude' },
    // { key: 'second', title: 'Stoicism' },
    { key: 'sobriety', title: 'Sobriety' },
  ]);

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: colours.yellow }}
      indicatorContainerStyle={{ backgroundColor: colours.three }}
      labelStyle={{
        color: colours.white,
        fontFamily: TAB_BAR_LABEL_FONT,
        letterSpacing: 1,
      }}
    />
  );
  
  return (
    <TabView
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      indicatorStyle={{ backgroundColor: TAB_VIEW_INDICATOR_STYLE }}
      style={{ backgroundColor: TAB_VIEW_STYLE }}
      pagerStyle={{ backgroundColor: TAB_VIEW_PAGER_STYLE }}
      tabBarPosition='bottom'
    />
  );
};
