import React, { useEffect } from 'react';

import {
  SafeAreaView,
  ScrollView,
  FlatList,
  StyleSheet,
  View,
  Pressable,
} from 'react-native';

import { FadeInView } from '../components/FadeIn';
import ChipCard from '../components/ChipCard';
import { EmptyComponent } from '../components/Empty';

import SOBRIETY_CHIPS_DATA from '../data/sobriety-chips.json';
import BACKGROUNDS from '../data/earthy-colours-rgb.json';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  item: {
    width: '50%',
  },
});

export const SobrietyChipsScreen = ({ navigation, route }) => {
  const { type, title } = route.params;

  console.log('SOBRIETY_CHIPS_DATA', SOBRIETY_CHIPS_DATA);
  console.log('BACKGROUNDS', BACKGROUNDS);

  return (
    <FadeInView>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        directionalLockEnabled={true}
        alwaysBounceVertical={false}
        contentContainerStyle={{ width: '100%' }}
        style={styles.container}
      >
        <FlatList
          contentContainerStyle={{ alignSelf: 'flex-start', width: '100%' }}
          columnWrapperStyle={{ width: '100%' }}
          numColumns={2}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          data={SOBRIETY_CHIPS_DATA}
          renderItem={({ item, index }) => {
            const data = {
              ...item,
              backgroundColor: `rgb(${BACKGROUNDS.colors[index].rgb})`,
              single: false,
            };
            return (
              <Pressable
                style={styles.item}
                onPress={() =>
                  navigation.navigate('SobrietySingleChip', {
                    screen: 'SobrietySingleChip',
                    initial: false,
                    id: item.order
                  })
                }
              >
                <ChipCard {...data }/>
              </Pressable>
            );
          }}
          ListEmptyComponent={() => <EmptyComponent />}
          keyExtractor={(item) => `chip__${item.order}`}
        />
      </ScrollView>
    </FadeInView>
  );
};
