import React from 'react';
import {ActivityIndicator, View, StyleSheet} from 'react-native';

export const Loader = props => {

  const styles = StyleSheet.create({
    loader: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    }
  });
  

  return (
    <View style={styles.loader}>
        <ActivityIndicator size="large" color="#000" />
    </View>
  );
};