import React, { useCallback, useState } from 'react';

import { View, Text, Alert, ScrollView, Platform } from 'react-native';

import { WebView } from 'react-native-webview';

import { FadeInView } from '../components/FadeIn';

import { styles, colours } from '../styles/main';

export const JustForTodayScreen = ({ navigation, route }) => {
  const { type, title } = route.params;

  const [playing, setPlaying] = useState(false);

  const onStateChange = useCallback((state) => {
    if (state === 'ended') {
      setPlaying(false);
      Alert.alert('video has finished playing!');
    }
  }, []);

  const togglePlaying = useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  return (
    <FadeInView>
      <ScrollView>
        <View style={styles.pageContainer}>
          <Text style={styles.pageParagraph}>
            The Just for Today card is a piece of literature found in most
            Alcoholics Anonymous (AA) meetings.
          </Text>
          <Text style={styles.pageParagraph}>
            A handy pocket sized Alcoholics Anonymous (AA) card can be bought
            from the AA website and an online version downloaded for free.
          </Text>
          <Text style={styles.pageParagraph}>
            Below are the video and text versions:
          </Text>
          {Platform.OS !== 'web' && (
            <WebView
              style={{ flex: 1, marginBottom: 20, height: 230 }}
              javaScriptEnabled={true}
              source={{
                uri: 'https://www.youtube.com/embed/FvW2PKGcxUw?rel=0&autoplay=0&showinfo=0&controls=0',
              }}
            />
          )}
          {Platform.OS === 'web' && (
            <View style={styles.video}>
              <iframe height="315" src="https://www.youtube.com/embed/FvW2PKGcxUw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </View>
          )}
          <Text style={styles.pageParagraph}>
            Just for today I will try to live through this day only, and not
            tackle my whole life problem at once. I can do something for twelve
            hours that would appal me if I felt that I had to keep it up for a
            lifetime.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will be happy. Most folks are as happy as they make
            up their minds to be.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will adjust myself to what is, and not try to
            adjust everything to my own desires. I will take my ‘luck’ as it
            comes, and fit myself to it.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will try to strengthen my mind. I will study. I
            will learn something useful. I will not be a mental loafer. I will
            read something that requires effort, thought and concentration.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will exercise my soul in three ways: I will do
            somebody a good turn, and not get found out; if anybody knows of it,
            it will not count. I will do at least two things I don’t want to do
            —just for exercise. I will not show anyone that my feelings are
            hurt; they may be hurt, but today I will not show it.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will be agreeable. I will look as well as I can,
            dress becomingly, talk low, act courteously, criticise not one bit,
            not find fault with anything and not try to improve or regulate
            anybody except myself.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will have a programme. I may not follow it exactly,
            but I will have it. I will save myself from two pests: hurry and
            indecision.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will have a quiet half hour all by myself, and
            relax. During this half hour, sometime, I will try to get a better
            perspective of my life.
          </Text>
          <Text style={styles.pageParagraph}>
            Just for today I will be unafraid. Especially I will not be afraid
            to enjoy what is beautiful, and to believe that as I give to the
            world, so the world will give to me.
          </Text>
        </View>
      </ScrollView>
    </FadeInView>
  );
};
