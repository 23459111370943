import { useCallback } from 'react';

import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, Button, View, Linking, Image } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';

import { createDrawerNavigator } from '@react-navigation/drawer';

import { useFonts } from 'expo-font';

import { styles, colours } from './styles/main';

import * as SplashScreen from 'expo-splash-screen';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { HEADER_TITLE_FONT } from './config/config';

import Ionicons from '@expo/vector-icons/Ionicons';
// browse icons here: https://oblador.github.io/react-native-vector-icons/ListScreen

import { HomeScreen } from './screens/Home';
import { ListScreen } from './screens/List';
import { SobrietyChipsScreen } from './screens/SobrietyChipsScreen';
import { JustForTodayScreen } from './screens/JustForTodayScreen';
import { GratitudeWallScreen } from './screens/GratitudeWallScreen';
import { ThankyouCardScreen } from './screens/ThankyouCardScreen';
import { SobrietySingleChipScreen } from './screens/SobrietySingleChipScreen';
import { SobrietySingleQuoteScreen } from './screens/SobrietySingleQuoteScreen';
import { ContactScreen } from './screens/Contact';

const Stack = createNativeStackNavigator();

SplashScreen.preventAutoHideAsync();

const drawerItemStyle = { paddingLeft: 0 };
const drawerLabelStyle = { color: colours.white };
const drawerActiveTintColor = colours.white;
const drawerInactiveTintColor = colours.white;
const drawerActiveBackgroundColor = 'rgba(255, 255, 255, 0.1)';
const drawerInactiveBackgroundColor = colours.three;
const drawerScreenOptions = {
  headerTitleAlign: 'center',
  headerStyle: { backgroundColor: colours.two },
  headerTintColor: 'white',
  drawerItemStyle,
  // drawerLabelStyle,
  drawerActiveTintColor,
  drawerInactiveTintColor,
  drawerActiveBackgroundColor,
  drawerInactiveBackgroundColor,
};

export default function App() {
  const [fontsLoaded] = useFonts({
    Raleway: require('./assets/fonts/Raleway-Regular.ttf'),
    PlayfairDisplay: require('./assets/fonts/PlayfairDisplay-Regular.ttf'),
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  const Drawer = createDrawerNavigator();

  const handleYouTubeLink = async() => {
    const url = 'https://www.youtube.com/GratefulToday';
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  };

  const AboutScreen = ({ navigation }) => {
    return (
      <View style={styles.pageContainer}>
        <Text style={styles.pageParagraph}>
          Grateful Today is a website to help cultivate an attitude of gratitude
          with Gratitude quotes, affirmations and videos.
        </Text>
        <Text style={styles.pageParagraph}>
          We are addiction/recovery focused. So you will be recovery related
          content on Grateful Today, for example links to Alcoholics Anonymous
          (AA).
        </Text>
        <Text style={styles.pageParagraph}>
          We also have a You Tube channel to accompany our app. Please visit and
          subscribe to help us reach more people.
        </Text>
        <Text style={styles.pageParagraph}>
          If you are interested in contributing to this app, please get in touch
          via the contact page. We would love to hear from you.
        </Text>
        <View style={styles.pageParagraph}>
          <Button
            onPress={() =>
              navigation.navigate('Contact', {
                screen: 'ContactScreen',
                initial: false,
              })
            }
            color={colours.tertiary}
            title='Contact us'
          />
        </View>
        <View style={styles.pageParagraph}>
          <Button
            onPress={() =>
              handleYouTubeLink()
            }
            color={colours.tertiary}
            title='YouTube'
          />
        </View>
        <Button
          onPress={() => navigation.goBack()}
          color={colours.tertiary}
          title='Go back home'
        />
      </View>
    );
  };

  const CustomHeaderTitle = () => {
    return (
      <View style={styles.headerTitleContainer}>
        <Image
          source={require('./assets/header-icon.png')}
          style={{ height: 32, width: 32 }}
        />
      </View>
    );
  };

  const HomeRoot = ({ navigation }) => {
    return (
      <Drawer.Navigator
        initialRouteName='Home'
        screenOptions={{
          drawerStyle: {
            backgroundColor: colours.three,
            color: colours.white,
            width: 240,
          },
        }}
      >
        <Drawer.Screen
          name='HomeScreen'
          component={HomeScreen}
          options={{
            ...drawerScreenOptions,
            title: 'Grateful Today',
            headerTitle: () => <CustomHeaderTitle />,
            drawerLabel: 'Home',
            drawerIcon: ({ focused, size }) => (
              <Image
                source={require('./assets/home-icon.png')}
                style={[
                  focused ? styles.drawerActive : styles.drawerInActive,
                  { height: 20, width: 20 },
                ]}
              />
            ),
          }}
        />

        <Drawer.Screen
          name='About'
          component={AboutScreen}
          options={{
            ...drawerScreenOptions,
            drawerIcon: (config) => (
              <Ionicons name='document-text-outline' size={18} color='white' />
            ),
          }}
        />
        <Drawer.Screen
          name='Contact Us'
          component={ContactScreen}
          options={{
            ...drawerScreenOptions,
            drawerIcon: (config) => (
              <Ionicons name='mail-outline' size={18} color='white' />
            ),
          }}
        />
      </Drawer.Navigator>
    );
  };

  return (
    <NavigationContainer onReady={onLayoutRootView}>
      <StatusBar style='light' />
      <Stack.Navigator
        initialRouteName='Home'
        screenOptions={{
          headerTintColor: 'white',
          headerStyle: { backgroundColor: colours.two },
          headerTitleAlign: 'center',
          headerTitleStyle: {
            fontFamily: HEADER_TITLE_FONT,
            alignSelf: 'center',
            paddingTop: 5,
            // paddingLeft: 10,
            letterSpacing: 1 /* @TODO: this does not work on devices */,
          },
        }}
      >
        <Stack.Screen
          name='Home'
          component={HomeRoot}
          options={{
            title: 'Grateful Today',
            headerShown: false,
          }}
        />
        <Stack.Screen name='List' component={ListScreen} />
        <Stack.Screen
          name='SobrietyChips'
          options={{ title: 'Sobriety Chips' }}
          component={SobrietyChipsScreen}
        />
        <Stack.Screen
          name='JustForToday'
          options={{ title: 'Just For Today' }}
          component={JustForTodayScreen}
        />
        <Stack.Screen
          name='ThankYouCard'
          options={{ title: 'Thank You Card' }}
          component={ThankyouCardScreen}
        />
        <Stack.Screen
          name='GratitudeWall'
          options={{ title: 'Gratitude Wall' }}
          component={GratitudeWallScreen}
        />
        <Stack.Screen
          name='SobrietySingleChip'
          options={{ title: 'Sobriety Single Chip' }}
          component={SobrietySingleChipScreen}
        />
        <Stack.Screen
          name='SobrietySingleQuote'
          options={{ title: 'Sobriety Single Quote' }}
          component={SobrietySingleQuoteScreen}
        />
        <Stack.Screen
          name='Contact'
          options={{ title: 'Contact Us' }}
          component={ContactScreen}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
