import React from 'react';

import { Text, View, StyleSheet } from 'react-native';

import Ionicons from '@expo/vector-icons/Ionicons';

import { colours } from '../styles/main';

export const TextCard = (props) => {

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colours[props.theme] || colours.tertiary,
      borderRadius: 8,
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      color: colours.white
    },
    title: {
      color: colours.white,
      fontSize: 22,
      marginBottom: 10,
      letterSpacing: 1,
    },
    subtitle: {
      color: colours.white,
      fontSize: 16,
      color: colours.white,
      letterSpacing: 1
    },
  });

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.subtitle}>{props.subtext}</Text>
      </View>
      <Ionicons name='arrow-forward-outline' size={24} color={colours.white} />
    </View>
  );
};
