import React, { useEffect, useRef } from 'react';

import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import {
  requestPermissionsAsync,
  createAssetAsync,
  saveToLibraryAsync,
} from 'expo-media-library';

import Ionicons from '@expo/vector-icons/Ionicons';

import { StyleSheet, View, Pressable, Platform } from 'react-native';

import { colours } from '../styles/main';

import { FadeInView } from '../components/FadeIn';
import ChipCard from '../components/ChipCard';
import { CtaButton } from '../components/CtaButton';

import SOBRIETY_CHIPS_DATA from '../data/sobriety-chips.json';
import BACKGROUNDS from '../data/earthy-colours-rgb.json';

export const SobrietySingleChipScreen = ({ navigation, route }) => {
  const { id } = route.params;
  const childRef = useRef();

  const CHIP_DATA_INDEX = SOBRIETY_CHIPS_DATA.findIndex(
    (chip) => chip.order === id
  );

  const chip = {
    ...SOBRIETY_CHIPS_DATA[CHIP_DATA_INDEX],
    backgroundColor: `rgb(${BACKGROUNDS.colors[CHIP_DATA_INDEX].rgb})`,
    single: true,
  };

  const styles = StyleSheet.create({
    container: {
      backgroundColor: chip.backgroundColor,
      width: '100%',
      height: '100%',
    },
  });

  useEffect(() => {
    navigation.setOptions({
      title: chip.title,
    });
  }, [chip.title]);

  const shareOnWeb = async () => {
    try {
      // Get the inline SVG element
      const svgElement = document.getElementById(`chipSvg_${chip.chipNumber}_${chip.chipText}`);

      // Serialize the SVG code
      const svgCode = new XMLSerializer().serializeToString(svgElement);

      // Create a new DOMParser
      const parser = new DOMParser();

      // Parse the SVG code into a Document object
      const svgDoc = parser.parseFromString(svgCode, 'image/svg+xml');

      // Create a canvas element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Set the canvas size to match the SVG dimensions
      const width = svgDoc.documentElement.getAttribute('width');
      const height = svgDoc.documentElement.getAttribute('height');
      /* 
      canvas.width = parseInt(width);
      canvas.height = parseInt(height);
      */

      canvas.width = parseInt(800);
      canvas.height = parseInt(800);

      // Create a new Image element
      const image = new Image();

      // Wait for the image to load
      await new Promise((resolve) => {
        image.onload = resolve;
        image.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgCode)}`;
      });

      // Draw the image onto the canvas
      context.drawImage(image, 0, 0);

      // Convert the canvas to a PNG image
      const pngDataUrl = canvas.toDataURL('image/png');

      // Create a Blob from the PNG data
      const byteString = atob(pngDataUrl.split(',')[1]);
      const mimeString = pngDataUrl.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // Create a File object from the Blob
      const file = new File([blob], 'file.png', { type: 'image/png' });

      // Use the Web Share API to share the PNG file
      if (navigator.share) {
        await navigator.share({
          title: `${chip.chipNumber} ${chip.chipText} Sobriety Chip`,
          files: [file],
          text: 'Sobriety Chips:',
          url: 'https://grateful.today/sobriety-chips.html'
        });
      } else {
        console.log('Web Share API not supported');
        alert('Web Share API not supported');
      }
    } catch (error) {
      console.error('Error sharing SVG as PNG:', error);
    }
  };

  /*
  const share = async (url, fileName) => {
    const blob = await fetch(url).then((response) => response.blob());
    const blobFile = new File([blob], `${fileName}.mp4`, { type: 'video/mp4' });
    try {
      await navigator.share({
        title: 'Sobriety Chip',
        files: [blobFile],
        text: 'Get your Sobriety Chip at:',
        url: 'https://grateful.today/sobriety-chips.html',
      });
      analyticClickEvent('share_sobriety_chip', `chip_${fileName}`);
    } catch (error) {
    }
  }; */

  const share = async (expo) => {
    if (Platform.OS === 'web') {
      return shareOnWeb();
    }

    const base64Data = await childRef.current.getSvgBase64();

    const filename = FileSystem.cacheDirectory + `${chip.chipNumber}-${chip.chipText}.svg`;

    await FileSystem.writeAsStringAsync(filename, base64Data, {
      encoding: FileSystem.EncodingType.Base64,
    });

    let fileUri;

    console.log('filename', filename);

    try {
      const permission = await requestPermissionsAsync();
      if (permission.status === 'granted') {
        fileUri = await saveToLibraryAsync(filename);
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (expo) {
        // no Mail app shown for iOS
        console.log('fileUri', filename);
        await Sharing.shareAsync(filename);
      } else {
        // Mail app shows
        await Share.share({
          url: fileUri, // iOS only
        });
      }
    } catch (err) {
      console.log('Failed to share', err);
    }
  };

  const shareChip = async () => {
    console.log('shareChip');
    share(true);
  };

  return (
    <FadeInView style={styles.container}>
      <ChipCard {...chip} ref={childRef} />
      <Pressable onPress={() => shareChip()}>
        <CtaButton
          icon={
            <Ionicons name='md-share-social' size={32} color={colours.white} />
          }
        >
          Share Chip
        </CtaButton>
      </Pressable>
    </FadeInView>
  );
};
