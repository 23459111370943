import React, { useEffect, useState } from 'react';

import { SafeAreaView, FlatList, Pressable } from 'react-native';

import { FadeInView } from '../components/FadeIn';
import { QuoteCard } from '../components/QuoteCard';
import { EmptyComponent } from '../components/Empty';

import { debounce }  from '../utils/helpers';

import algoliasearch from 'algoliasearch';

const client = algoliasearch(
  'GXG55PZNKI',
  '7e27651cfc1f306061c9d6505252e2a6'
);
const index = client.initIndex('grateful_today');

// https://blog.logrocket.com/create-react-native-search-bar-from-scratch/
import SearchBar from '../components/SearchBar';

import { styles } from '../styles/main';

const DATA = {
  quote: [
    {
      id: 1,
      quote:
        'In daily life we must see that it is not happiness that makes us grateful, but gratefulness that makes us happy.',
      author: 'David Steindl-Rast',
    },
    {
      id: 2,
      quote: "You can't be unhappy and grateful at the same time.",
      author: 'Unknown',
    },
    {
      id: 3,
      quote:
        'Let us be grateful to the people who make us happy; they are the charming gardeners who make our souls blossom.',
      author: 'Marcel Proust',
    },
    {
      id: 4,
      quote: 'Walk as if you are kissing the Earth with your feet.',
      author: 'Thich Nhat Hanh',
    },
    {
      id: 5,
      quote:
        'Gratitude makes sense of our past, brings peace for today, and creates a vision for tomorrow.',
      author: 'Melody Beattie',
    },
    {
      id: 6,
      quote:
        'If you concentrate on what you don’t have, you will never, ever have enough.',
      author: 'Oprah Winfrey',
    },
    {
      id: 7,
      quote:
        'The more light you allow within you, the brighter the world you live in will be.',
      author: 'Shakti Gawain',
    },
    {
      id: 8,
      quote: 'If you have lived, take thankfully the past.',
      author: 'John Dryden',
    },
    {
      id: 9,
      quote:
        "Let us rise up and be thankful, for if we didn't learn a lot today, at least we learned a little, and if we didn't learn a little, at least we didn't get sick, and if we got sick, at least we didn't die; so, let us all be thankful.",
      author: 'Buddha',
    },
    {
      id: 10,
      quote:
        'He is a wise man who does not grieve for the things which he has not, but rejoices for those which he has. ',
      author: 'Epictetus',
    },
  ],
  affirmation: [
    {
      id: 1,
      quote: 'I respect myself and my body.',
    },
    {
      id: 2,
      quote: 'I will surround myself with people who support my recovery.',
    },
    {
      id: 3,
      quote: 'I am learning new ways to cope.',
    },
    {
      id: 4,
      quote: 'I am creating the life I want.',
    },
    {
      id: 5,
      quote: 'I am appreciating all that I have.',
    },
    {
      id: 6,
      quote: 'I am realising how fortunate I am that so many people love me.',
    },
    {
      id: 7,
      quote:
        'Every person I meet can teach me something. I am grateful for their wisdom.',
    },
    {
      id: 8,
      quote: 'I am appreciating all that I have.',
    },
    {
      id: 9,
      quote:
        'Every day I should be working my recovery, otherwise I am working my relapse.',
    },
    {
      id: 10,
      quote: 'I am not my thoughts, I am my actions.',
    },
  ],
};

export const ListScreen = ({ navigation, route }) => {
  const { type, title } = route.params;
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchResults, setSearchResults] = useState(DATA[type]);
  const [clicked, setClicked] = useState(false);

  const updateSearch = async () => {
    console.log('updateSearch with', searchPhrase);
    const result = await index.search(searchPhrase);
    console.log('search results', result.hits)
    setSearchResults(result.hits);
  }

  useEffect(() => {
    const delay = 300; // Debounce delay in milliseconds
    let timerId;
    
    const debounceSearch = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        if (searchPhrase.length > 2) {
          updateSearch();
        }
        // Call your search API here with the debounced search phrase
      }, delay);
    };
    
    debounceSearch(); // Initial call when searchPhrase changes
    
    return () => {
      clearTimeout(timerId);
    };
  }, [searchPhrase]);

  return (
    <FadeInView>
      <SafeAreaView style={styles.resultsContainer}>
        <SearchBar
          searchPhrase={searchPhrase}
          setSearchPhrase={setSearchPhrase}
          clicked={clicked}
          setClicked={setClicked}
        />
        <FlatList
          data={searchResults}
          renderItem={({ item, index }) => (
            <Pressable
              style={styles.item}
              onPress={() =>
                navigation.navigate('SobrietySingleQuote', {
                  screen: 'SobrietySingleQuote',
                  initial: false,
                  quote: item.quote,
                  author: item.author,
                })
              }
            >
              <QuoteCard {...item} searchPhrase={searchPhrase} backgroundColor='#fefefe' />
            </Pressable>
          )}
          ListEmptyComponent={() => <EmptyComponent />}
          keyExtractor={(item) => item.id}
        />
      </SafeAreaView>
    </FadeInView>
  );
};
