import { Platform } from 'react-native';

export const PRIMARY_QUOTE_FONT =
  Platform.OS == 'web' ? 'Raleway' : 'sans-serif-medium';
export const SECONDARY_QUOTE_FONT = 'monospace';

export const HEADER_TITLE_FONT = Platform.OS === 'web' ? 'Raleway' : 'sans-serif-medium';

// TABS
export const TAB_BAR_LABEL_FONT =  Platform.OS === 'web' ? 'Raleway' : 'sans-serif-condensed';
export const TAB_VIEW_INDICATOR_STYLE = '#673ab7';
export const TAB_VIEW_STYLE = '#673ab7';
export const TAB_VIEW_PAGER_STYLE = '#673ab7';

// THANK YOU
export const DEFAULT_THANK_YOU_CARD_TITLE = 'Thank You';
export const DEFAULT_THANK_YOU_CARD_TEXT = 'Thank you so much for your thoughtfulness and generosity.';
