import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';

const getSvgBase64 = (canvasRef) => {
    console.log('getSvgBase64');
    return new Promise(async (resolve, reject) => {
      console.log('START 1');
      try {
        console.log('canvasRef.current', canvasRef.current);
        const base64 = await canvasRef.current.toDataURL();
        const base64String = base64.split(',')[1];
        resolve(base64String);
      } catch (error) {
        reject(error);
        console.error('useImperativeHandle getSvgBase64 error', error);
      }
    });
  };

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const shareOnWeb = async (canvasRef) => {
  let base64String;

  try {
    base64String = await getSvgBase64(canvasRef);
    console.log('base64String', base64String);
  } catch (error) {
    console.log('base64String error', error);
  }

  const blob = b64toBlob(base64String);

  console.log('blob', blob);

  // Create a File object from the Blob
  const file = new File([blob], 'file.png', { type: 'image/png' });

  // Use the Web Share API to share the PNG file
  if (navigator.share) {
    await navigator.share({
      files: [file],
      text: 'Gratitude Quotes:',
      url: 'https://gratefultoday.com',
    });
  } else {
    console.log('Web Share API not supported');
  }
};

export const handleShare = async (expo, canvasRef) => {
  if (Platform.OS === 'web') {
    return shareOnWeb(canvasRef);
  }

  let base64Data;

  try {
    base64Data = await getSvgBase64(canvasRef);
    console.log('base64Data', base64Data);
  } catch (error) {
    console.log('base64Data error', error);
  }

  console.log('12212121');

  const filename = FileSystem.cacheDirectory + `quote.png`;

  await FileSystem.writeAsStringAsync(filename, base64Data, {
    encoding: FileSystem.EncodingType.Base64,
  });

  let fileUri;

  try {
    if (expo) {
      // no Mail app shown for iOS
      console.log('fileUri', filename);
      await Sharing.shareAsync(filename);
    } else {
      // Mail app shows
      await Share.share({
        url: fileUri, // iOS only
      });
    }
  } catch (err) {
    console.log('Failed to share', err);
  }
};
