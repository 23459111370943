import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';

import { View, StyleSheet } from 'react-native';

import * as SVG from 'react-native-svg';
const {
  Circle,
  Ellipse,
  G,
  Text,
  TSpan,
  TextPath,
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
  Svg,
} = SVG;

const ChipCard = forwardRef((props, ref) => {
  const [svgRef, setSvgRef] = useState(null);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: `rgb(${props.backgroundColor})`,
      padding: 16,
      height: '100%',
      width: '100%',
      flex: 1,
    },
  });

  useImperativeHandle(ref, () => ({
    getSvgBase64: () => {
      console.log('getSvgBase64');
      setTimeout(() => {
        setIsSharing(true);
      }, 0)
      return new Promise((resolve) => {
        svgRef?.toDataURL((base64) => {
          setIsSharing(false);
          resolve(base64);
        });
      });
    },
  }));

  const setWidth = () => {
    /* 
    if (isSharing) {
      return 250
    }
    return '100%'; */
    return '100%';
  }

  const setHeight = () => {
    /* 
    if (isSharing) {
      return 250
    }
    if (props.single) {
      return '100%'
    } */
    return '100%';
  }

  return (
    <View style={styles.container}>
      <Svg
        ref={setSvgRef}
        xmlns='http://www.w3.org/2000/svg'
        id={`chipSvg_${props.chipNumber}_${props.chipText}`}
        x='0'
        y='0'
        viewBox='0 0 142.72 142.73'
        width={setWidth()}
        height={setHeight()}
      >
        <G>
          <Defs>
            <Path
              fill='white'
              id={`SVGID_${props.index}`}
              d='M102.77,135.44c-35.38,17.34-78.13,2.71-95.47-32.67S4.58,24.64,39.97,7.3s78.13-2.71,95.47,32.67
                c11.51,23.48,9.25,51.37-5.88,72.69C122.68,122.38,113.47,130.21,102.77,135.44z M51.1,86.65c-2.97-4.21-4.6-9.22-4.68-14.37
                l13-22.31c8.03-4.51,17.9-4.25,25.68,0.68l11.8,20.56c0,0.33,0,0.65,0,1c-0.05,8.91-4.8,17.13-12.5,21.62l-25.54-0.06
                C55.8,91.95,53.16,89.53,51.1,86.65z M55.4,94.16H33.19l11.1-19.13C45.14,82.68,49.17,89.62,55.4,94.16z M110.65,94.3l-22.86-0.04
                c6.53-4.72,10.65-12.07,11.27-20.1L110.65,94.3z M83.65,47.3c-7.21-3.51-15.6-3.69-22.95-0.49l11.4-19.65L83.65,47.3z
                 M99.19,49.46c-0.03,0.35-0.23,0.65-0.54,0.81c-0.93,0.53-1.72,0.22-2.37-0.92l-0.21-0.37l2.41-1.37l0.31,0.55
                C99.04,48.54,99.18,48.99,99.19,49.46z M45.58,101.61c0,1-0.65,1.56-1.93,1.56h-0.41v-2.7h0.61
                C45,100.47,45.58,100.85,45.58,101.61z M55.03,100.47V99.3H49.7v8.52h5.6v-1.21h-3.82v-2.62h2.91v-1.14h-2.91v-2.38L55.03,100.47z
                 M48.03,57.97l0.93-1.52l-7.28-4.5l-0.94,1.52L48.03,57.97z M34.87,62.97l-0.82,1.32l4.54,2.87c0.53,0.27,0.96,0.7,1.23,1.23
                c0.15,0.46,0.07,0.96-0.21,1.35c-0.23,0.46-0.67,0.77-1.17,0.84c-0.63-0.01-1.24-0.22-1.74-0.6l-4.51-2.82l-0.94,1.52l4.46,2.76
                c0.52,0.33,1.08,0.58,1.68,0.72c0.47,0.07,0.94,0.02,1.38-0.16c0.77-0.34,1.41-0.93,1.82-1.67c0.37-0.57,0.58-1.24,0.6-1.92
                c0-0.08,0-0.17,0-0.25c-0.03-0.46-0.2-0.9-0.49-1.26c-0.41-0.46-0.89-0.85-1.42-1.15L34.87,62.97z M81.95,99.3h-1.28l-2.36,6.6
                l-2.44-6.6h-1.9l3.15,8.52h1.78L81.95,99.3z M82.32,120c-26.39,5.99-52.64-10.54-58.63-36.93s10.54-52.64,36.93-58.63
                s52.64,10.54,58.63,36.93c4.1,18.04-2.32,36.85-16.58,48.63C96.76,114.88,89.79,118.31,82.32,120z M48.49,107.82l-2.79-4
                c0.49-0.21,0.91-0.56,1.21-1c0.3-0.42,0.45-0.92,0.45-1.43c0-1.41-0.87-2.12-2.6-2.12h-3.19v8.52h1.67v-3.48h1l2.11,3.48
                L48.49,107.82z M46.02,61.16l0.84-1.36l-7.28-4.5l-0.75,1.22l5,3.07l-7.06,0.3l-0.82,1.34l7.24,4.49l0.75-1.22l-5-3.07
                L46.02,61.16z M71.42,103.55c0.04,0.86-0.19,1.71-0.67,2.43c-0.41,0.58-1.09,0.91-1.8,0.89c-0.7,0.02-1.36-0.32-1.75-0.9
                c-0.47-0.71-0.7-1.55-0.66-2.4c-0.05-0.85,0.18-1.7,0.65-2.41c0.72-0.98,2.09-1.19,3.07-0.48c0.18,0.13,0.34,0.29,0.48,0.48
                c0.48,0.7,0.72,1.53,0.68,2.38V103.55z M88.42,100.48V99.3h-5.29v8.52h5.6v-1.21h-3.82v-2.62h2.91v-1.14h-2.91v-2.38L88.42,100.48
                z M94.19,101.61c0,1-0.64,1.56-1.93,1.56h-0.41v-2.7h0.62C93.65,100.47,94.19,100.85,94.19,101.61z M100.19,66.02l7.59-4.32
                l-0.9-1.58l-7.59,4.32L100.19,66.02z M102.33,68.84c-0.02,0.94,0.24,1.87,0.73,2.67c0.49,0.92,1.17,1.72,2,2.35l1.17-0.66
                c-0.76-0.49-1.39-1.15-1.85-1.93c-0.46-0.71-0.58-1.58-0.34-2.39c0.32-0.84,0.94-1.53,1.75-1.92c0.75-0.49,1.64-0.69,2.53-0.58
                c0.81,0.16,1.5,0.69,1.87,1.42c0.42,0.82,0.7,1.7,0.83,2.61l1.24-0.71c-0.27-0.92-0.64-1.8-1.11-2.63
                c-0.55-1.14-1.54-2.01-2.74-2.41c-2.51-0.42-4.97,0.98-5.89,3.35c-0.08,0.27-0.14,0.55-0.15,0.83H102.33z M115.83,75.84
                l-2.64-4.75l-7.6,4.32l2.84,5l1.08-0.62l-1.94-3.4l2.34-1.33l1.48,2.59l1-0.58l-1.48-2.59l2.12-1.21l1.81,3.17L115.83,75.84z
                 M97.51,99.3l2.87,5v3.57h1.81v-3.58l3-4.94h-1.41l-2.17,3.6l-2.08-3.6L97.51,99.3z M97.13,107.82l-2.78-4
                c0.49-0.21,0.91-0.56,1.21-1c0.29-0.42,0.44-0.92,0.44-1.43c0-1.41-0.87-2.12-2.6-2.12h-3.21v8.52h1.67v-3.48h1l2.12,3.48
                L97.13,107.82z M30.28,95.01c0.01,0.27,0.09,0.54,0.23,0.77c0.25,0.38,0.68,0.6,1.13,0.61l80.57,0.19
                c0.46,0.01,0.89-0.22,1.14-0.6c0.13-0.19,0.2-0.42,0.22-0.65c0.02-0.27-0.04-0.54-0.17-0.78L73.28,24.67
                c-0.26-0.44-0.75-0.71-1.26-0.68c-0.45,0.03-0.86,0.28-1.09,0.68L30.47,94.35C30.35,94.55,30.28,94.77,30.28,95.01z M42.28,50.93
                l1,0.62l1.61-2.61l6.3,3.88l0.94-1.52l-6.29-3.88l1.61-2.61l-1-0.62L42.28,50.93z M46.63,43.93l5.75,0.16l3,1.89l0.94-1.52
                l-3.03-1.93l-2.65-5.15l-0.74,1.2l1.93,3.75l-4.17-0.12L46.63,43.93z M83.86,37.12c0.25,0.79,0.59,1.55,1,2.27
                c0.39,0.76,1.01,1.38,1.77,1.77c0.25,0.11,0.52,0.17,0.79,0.19c0.46,0.01,0.92-0.11,1.31-0.35c0.45-0.25,0.81-0.65,1-1.13
                c0.22-0.7,0.31-1.43,0.27-2.16v-0.72c-0.01-0.39,0.02-0.78,0.11-1.16c0.07-0.22,0.22-0.41,0.42-0.53c0.25-0.14,0.55-0.14,0.8,0
                c0.31,0.15,0.55,0.4,0.71,0.7c0.32,0.66,0.52,1.36,0.61,2.09l1.22-0.69c-0.22-0.77-0.52-1.5-0.91-2.2c-0.34-0.67-0.91-1.2-1.6-1.5
                c-0.63-0.24-1.33-0.18-1.91,0.17c-0.45,0.25-0.81,0.64-1,1.12c-0.22,0.66-0.3,1.36-0.26,2.05v0.67c0.02,0.4-0.02,0.79-0.12,1.18
                c-0.09,0.24-0.27,0.44-0.5,0.57c-0.28,0.18-0.63,0.21-0.94,0.07c-0.36-0.17-0.64-0.45-0.82-0.8c-0.36-0.75-0.57-1.56-0.62-2.39
                L83.86,37.12z M63.64,106.16c-0.78,0.4-1.65,0.62-2.53,0.63c-0.82,0.04-1.61-0.27-2.18-0.86c-0.56-0.67-0.84-1.54-0.79-2.41
                c-0.06-0.87,0.2-1.73,0.73-2.43c0.53-0.58,1.29-0.9,2.08-0.85c0.89,0.04,1.78,0.22,2.61,0.55v-1.37
                c-0.91-0.22-1.84-0.33-2.77-0.33c-1.23-0.08-2.44,0.34-3.36,1.16c-1.56,1.93-1.56,4.68,0,6.61c0.88,0.82,2.06,1.25,3.26,1.18
                c1.03,0.03,2.05-0.16,3-0.56L63.64,106.16z M64.64,103.54c-0.06,1.2,0.36,2.37,1.16,3.26c0.81,0.83,1.94,1.27,3.1,1.22
                c1.19,0.07,2.36-0.37,3.21-1.21c1.56-1.9,1.56-4.63,0-6.53c-1.79-1.61-4.51-1.61-6.3,0c-0.81,0.89-1.22,2.08-1.15,3.28
                L64.64,103.54z M91.75,43.16l1.44,2.61l1-0.57l-1.48-2.6l2.12-1.21l1.8,3.17l1-0.59l-2.7-4.75l-7.59,4.32l2.83,5l1.08-0.61
                l-1.94-3.41L91.75,43.16z M94.54,56l2.13-4.5c0.44,0.33,0.96,0.53,1.5,0.58c0.52,0.04,1.05-0.08,1.5-0.34
                c0.63-0.29,1.06-0.9,1.11-1.6c-0.01-0.63-0.2-1.25-0.54-1.79l-1.62-2.84l-7.59,4.32l0.84,1.49l3.11-1.77l0.49,0.88l-2,3.65
                L94.54,56z M96.28,59.07l0.91,1.6l9.13-1.6l-0.65-1.14l-7.07,1.23l4.64-5.52l-1-1.69L96.28,59.07z M23.85,40.77
                c0,0.43-0.12,0.85-0.35,1.21c-0.34,0.6-0.97,0.97-1.66,1c-0.83,0.03-1.65-0.21-2.33-0.69c-0.73-0.4-1.31-1.03-1.65-1.8
                c-0.44-1.1,0.08-2.34,1.18-2.79c0.21-0.09,0.43-0.14,0.66-0.15c0.83-0.03,1.64,0.21,2.32,0.68c0.74,0.41,1.32,1.04,1.67,1.81
                C23.78,40.27,23.83,40.52,23.85,40.77z M46.5,19.64c-0.01,0.17-0.03,0.34-0.07,0.51c-0.22,0.73-0.75,1.33-1.45,1.63l-1.14,0.6
                l-2.81-5.31l0.92-0.49c0.45-0.26,0.94-0.44,1.45-0.53c0.4-0.03,0.8,0.05,1.15,0.25c0.61,0.35,1.1,0.87,1.41,1.5
                C46.28,18.36,46.46,18.99,46.5,19.64z M54.36,15.59l-2.46,0.8l0.2-3.56L54.36,15.59z M76.19,13.23l-2.58-0.17l1.58-3.24
                L76.19,13.23z M97.64,15.42l-0.23,3.56l-2.35-1.1L97.64,15.42z M15.19,79.09c0.01,0.6-0.24,1.18-0.7,1.57
                c-0.63,0.55-1.43,0.87-2.26,0.93c-0.84,0.14-1.7,0.01-2.46-0.38c-0.62-0.34-1.03-0.97-1.08-1.68c-0.1-0.68,0.16-1.37,0.69-1.81
                c0.64-0.54,1.44-0.86,2.28-0.92c0.83-0.14,1.68-0.01,2.43,0.38c0.62,0.33,1.03,0.95,1.1,1.65C15.19,78.91,15.19,79,15.19,79.09z
                 M50.39,125.68c0,0.65-0.14,1.29-0.42,1.87c-0.3,0.8-0.84,1.47-1.56,1.93c-0.61,0.37-1.36,0.41-2,0.1
                c-0.64-0.26-1.1-0.84-1.22-1.52c-0.22-1.69,0.52-3.36,1.91-4.34c1.03-0.6,2.35-0.26,2.96,0.77c0.11,0.19,0.2,0.41,0.24,0.63
                c0.04,0.18,0.06,0.37,0.06,0.56L50.39,125.68z M113.33,112.56c-0.05,0.42-0.25,0.81-0.56,1.1l-0.29,0.29l-1.69-1.64l0.25-0.25
                c0.29-0.35,0.66-0.62,1.07-0.8c0.3-0.06,0.62,0.06,0.81,0.3c0.28,0.26,0.42,0.63,0.38,1H113.33z M116.33,114.8
                c0.01,0.08,0.01,0.15,0,0.23c-0.2,0.48-0.5,0.91-0.89,1.26l-0.26,0.26l-1.86-1.81l0.24-0.24c0.34-0.38,0.79-0.63,1.29-0.72
                c0.42-0.08,0.85,0.05,1.16,0.35c0.17,0.18,0.27,0.42,0.28,0.67H116.33z M128.4,87.46c0,0.28-0.04,0.56-0.12,0.83l-0.11,0.39
                l-2.57-0.7l0.16-0.58c0.29-1.1,0.8-1.55,1.53-1.35C127.96,86.16,128.44,86.77,128.4,87.46z M79.08,16.75l-2.71-8.53l-1.73-0.06
                l-3.77,8.12l1.26,0.08l1.06-2.2l3.44,0.22l0.73,2.28L79.08,16.75z M63.4,16.98l-0.57-3.46l2.08-5.25l-1.36,0.22l-1.52,3.83
                l-2.58-3.16l-1.89,0.32l3.56,4.32l0.57,3.45L63.4,16.98z M71.56,127.33l-1.4-0.09l-0.37,5.74l-3.53-6l-1.54-0.1l-0.53,8.42
                l1.4,0.09l0.37-5.74l3.5,6l1.57,0.1L71.56,127.33z M116.82,39.33l1,1.24l6.5-5.23l-1.27-1.57l-5.86,2.1l3.31-5.25l-1.45-1.79
                l-6.49,5.24l0.85,1.06l5-4l-3.28,5.2l0.88,1.09l5.74-2L116.82,39.33z M88.91,10.31l-7.54-1.65l-0.24,1.12l2.92,0.64l-1.53,7
                l1.7,0.37l1.53-7l2.92,0.64L88.91,10.31z M130.13,96.71l-6.66-2.92l1.21-2.76l-1.06-0.46l-3.12,7.13l1.06,0.46l1.21-2.76
                l6.65,2.92L130.13,96.71z M125.55,44.3l-1.36-2.49l2-1.14l1.7,3l1-0.56l-2.55-4.55l-7.28,4.07l2.68,4.78l1-0.58l-1.83-3.26
                l2.28-1.22l1.39,2.49L125.55,44.3z M125.68,84.68c-0.4,0.43-0.68,0.97-0.8,1.55l-0.83,3l8.14,2.25l0.43-1.6l-3.32-0.9l0.25-0.93
                l3.87-1.12l0.56-2l-4.52,1.63c-0.07-0.52-0.29-1.02-0.63-1.42c-0.33-0.38-0.77-0.66-1.26-0.79c-0.49-0.16-1.03-0.13-1.5,0.1
                C125.93,84.5,125.79,84.58,125.68,84.68z M130.19,67.93l0.11,2.88l-2.35,0.09l-0.13-3.51h-1.15l0.2,5.26l8.41-0.32l-0.21-5.52
                h-1.19l0.14,3.78l-2.59,0.09l-0.11-2.87L130.19,67.93z M126.6,76.36l5.26,0.6c0.58,0.02,1.15,0.22,1.61,0.58
                c0.33,0.34,0.47,0.83,0.37,1.29c-0.01,0.49-0.25,0.95-0.65,1.23c-0.57,0.25-1.19,0.32-1.8,0.2l-5.12-0.59l-0.2,1.75l5.12,0.58
                c0.58,0.11,1.17,0.11,1.75,0c0.45-0.12,0.86-0.37,1.17-0.72c0.54-0.63,0.86-1.41,0.92-2.24c0.13-0.76,0-1.54-0.37-2.22
                c-0.23-0.4-0.58-0.72-1-0.92c-0.55-0.24-1.14-0.38-1.74-0.42l-5.14-0.59L126.6,76.36z M120.98,109.88l-2.06-1.58l1.75-2.29
                l-0.89-0.68l-1.75,2.28l-1.84-1.45l2.14-2.78l-0.92-0.71l-3.2,4.18l6.68,5.12l3.37-4.38l-1-0.73L120.98,109.88z M59.69,126.02
                l-1.42-0.35l-2.87,5.65v-6.35l-1.61-0.4v8.68l1.63,0.4l2.69-5.29L58,134.28l1.62,0.4l4.15-7.66l-1.18-0.29l-3,5.57L59.69,126.02z
                 M79.56,133.58l0.23,1.42c0.79,0.05,1.59,0.01,2.37-0.1c0.85-0.09,1.64-0.46,2.26-1.05c0.37-0.38,0.58-0.87,0.6-1.4
                c0.02-0.19,0.02-0.37,0-0.56c-0.07-0.5-0.31-0.96-0.68-1.3c-0.56-0.44-1.19-0.78-1.87-1l-0.65-0.23c-0.35-0.12-0.69-0.28-1-0.49
                c-0.18-0.13-0.31-0.34-0.34-0.56c-0.05-0.26,0.03-0.54,0.23-0.72c0.24-0.23,0.55-0.37,0.88-0.41c0.7-0.07,1.41-0.02,2.09,0.14
                l-0.22-1.33c-0.76-0.05-1.53-0.02-2.28,0.09c-0.73,0.09-1.41,0.45-1.9,1c-0.43,0.49-0.6,1.14-0.48,1.78
                c0.08,0.49,0.32,0.93,0.69,1.26c0.53,0.42,1.13,0.75,1.77,0.95l0.61,0.21c0.36,0.12,0.7,0.29,1,0.51c0.45,0.38,0.5,1.05,0.12,1.49
                c0,0.01-0.01,0.01-0.01,0.02c-0.27,0.26-0.62,0.43-1,0.48C81.16,133.86,80.34,133.8,79.56,133.58z M43.35,127.08
                c0.01,0.41,0.09,0.82,0.23,1.21c0.4,1.08,1.25,1.93,2.32,2.34c1.05,0.53,2.28,0.6,3.38,0.18c1.09-0.48,1.94-1.39,2.36-2.51
                c0.32-0.69,0.49-1.44,0.47-2.2c-0.01-0.41-0.09-0.82-0.24-1.21c-0.98-2.17-3.44-3.24-5.7-2.49c-1.09,0.48-1.93,1.38-2.35,2.49
                C43.49,125.57,43.33,126.32,43.35,127.08z M19.88,109.81l1.07,1.35l6.68-5.12l-1.07-1.39L19.88,109.81z M28.68,36.43l-4.26-3.78
                l6.77,0.91l1-1.17l-6.17-5.53l-0.92,1l4.26,3.78l-6.83-0.89l-1,1.14l6.24,5.54L28.68,36.43z M105.83,121.27l-0.61-1l-2.47,1.54
                l-1.36-2.17l3-1.88l-0.61-1l-4.5,2.81l4.46,7.14l1.49-0.93l-1.88-3L105.83,121.27z M109.66,31.36l1.24,1.22l5.83-6l-1.24-1.22
                L109.66,31.36z M38.96,16.86l3.89,7.38l3-1.6c1.06-0.47,1.87-1.38,2.22-2.48c0.07-0.26,0.11-0.52,0.13-0.78v-0.22
                c-0.04-0.8-0.27-1.57-0.66-2.27c-0.44-1-1.27-1.77-2.3-2.14c-1.06-0.27-2.18-0.1-3.11,0.48L38.96,16.86z M37.42,26.43l-2.86,2.42
                l-1.66-2l2.18-1.84l-0.72-0.85l-2.17,1.88l-1.5-1.78l2.65-2.25l-0.75-0.85l-4,3.37l5.39,6.37l4.21-3.56L37.42,26.43z M58.32,17.81
                l-5.67-6.93L51,11.42l-0.51,8.93l1.2-0.39l0.14-2.38l3.28-1.07l1.52,1.85L58.32,17.81z M14.37,73.65l1.15,0.05l0.3-7.79h-1.16
                l-0.11,3l-7.26-0.3l-0.1,1.75l7.26,0.28L14.37,73.65z M10.54,83.36c0.61,0.13,1.24,0.15,1.86,0.07c1.18-0.07,2.29-0.61,3.06-1.51
                c0.62-0.78,0.93-1.76,0.87-2.76c0-0.16,0-0.31,0-0.48c-0.06-1.17-0.62-2.26-1.54-3c-0.97-0.69-2.17-0.97-3.34-0.78
                c-1.2,0.08-2.32,0.64-3.1,1.56c-0.72,0.89-1.03,2.05-0.85,3.18c0.07,1.17,0.63,2.26,1.54,3C9.48,82.98,9.99,83.23,10.54,83.36z
                 M14.12,100.79l0.9,1.51l3.3-2l1.77,3l-3.3,1.95l0.89,1.51l7.25-4.29l-0.89-1.5l-3,1.74l-1.77-3l2.94-1.74l-0.89-1.51
                L14.12,100.79z M20.87,95.61l-2.77-7.28l-1.08,0.41l1.08,2.82l-6.8,2.6l0.63,1.65l6.79-2.65l1.07,2.82L20.87,95.61z M21.03,44.61
                c0.27,0,0.54-0.03,0.81-0.08c1.12-0.26,2.07-0.99,2.62-2c0.67-0.95,0.91-2.14,0.66-3.28c-0.32-1.14-1.08-2.11-2.12-2.68
                c-0.51-0.33-1.08-0.57-1.67-0.7c-0.54-0.12-1.09-0.12-1.63,0c-2.29,0.65-3.71,2.95-3.27,5.29c0.31,1.14,1.07,2.1,2.1,2.67
                c0.75,0.49,1.61,0.77,2.5,0.8V44.61z M30.4,109.31l-1.07-1.1l-6,5.88l1,1l4.12-4l-1.41,6.79l1.1,1.12l6-5.88l-1-1l-4.11,4
                L30.4,109.31z M36.82,121.07l0.68-0.9l-2.31-1.73l1.43-1.86l2.79,2.13l0.7-0.91l-4.18-3.21l-5.12,6.69l4.38,3.36l0.72-1l-3-2.3
                l1.57-2L36.82,121.07z M85.57,125.39l2.6,8l5.26-1.71l-0.37-1.13l-3.59,1.17l-0.8-2.47l2.74-0.89l-0.35-1.07l-2.74,0.89
                l-0.73-2.24l3.34-1.08l-0.35-1.1L85.57,125.39z M106.29,109.02c5.42-5.02,9.69-11.17,12.5-18c2.43-6.04,3.65-12.49,3.59-19
                c-0.02-6.53-1.28-13-3.72-19.06c-2.23-5.45-5.42-10.47-9.42-14.8c-18.75-20.73-50.75-22.34-71.48-3.6s-22.34,50.75-3.6,71.48
                c8.68,9.6,20.71,15.51,33.61,16.51c13.55,1.17,26.99-3.27,37.17-12.3c0.4-0.35,0.86-0.75,1.35-1.22V109.02z M101.29,128.19
                l-0.51-1.09l-3.44,1.61l-3.04-6.55l-1.58,0.74l3.56,7.63L101.29,128.19z M97.19,22.55l1.61,0.76l0.59-8.93l-1.57-0.74l-6.47,6.18
                l1.14,0.54l1.7-1.65l3.17,1.45L97.19,22.55z M104.93,27.44l1.38,1.06l4.38-5.71l2.37,1.81l0.7-0.9l-6.12-4.7l-0.7,0.91l2.37,1.82
                L104.93,27.44z M108.78,112.69l6,5.88l2.18-2.24c0.53-0.47,0.88-1.1,1-1.79c0.06-0.59-0.17-1.18-0.62-1.57
                c-0.83-0.81-1.93-0.79-3.31,0.07c0.55-0.79,0.6-1.83,0.12-2.67c-0.07-0.1-0.15-0.19-0.24-0.28c-0.37-0.4-0.92-0.59-1.46-0.5
                c-0.65,0.15-1.24,0.5-1.69,1L108.78,112.69z'
            />
          </Defs>
          <Rect
            x='0%'
            y='0%'
            fill={`${props.backgroundColor}`}
            width='100%'
            height='100%'
          />
          <Use xlinkHref={`#SVGID_${props.index}`} overflow='visible' />
        </G>
        <Text
          x={props.chipNumberX}
          y={props.chipNumberY}
          fontSize='24'
          fontWeight='bold'
          fontFamily='Roboto'
          fill={`${props.backgroundColor}`}
        >
          {props.chipNumber}
        </Text>
        <Text
          x={props.chipTextX}
          y={props.chipTextY}
          fontSize='7'
          fontWeight='bold'
          fontFamily='Roboto'
          letterSpacing={0.5}
          fill={`${props.backgroundColor}`}
        >
          {props.chipText.toUpperCase()}
        </Text>
      </Svg>
    </View>
  );
});

export default ChipCard;
