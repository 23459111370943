import React from 'react';

import { Text, View } from 'react-native';

import { styles } from '../styles/main';

export const EmptyComponent = () => {
  return (
    <View style={styles.resultsContainer}>
      <Text style={styles.body1}>No results</Text>
    </View>
  );
};
