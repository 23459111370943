import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useState,
} from 'react';

import {
  Text,
  View,
  StyleSheet,
  TouchableHighlight,
  Image,
  Share,
  Platform,
  SafeAreaView,
  Dimensions,
  Image as RNimage,
} from 'react-native';

import { colours } from '../styles/main';

import { StatusBar } from 'expo-status-bar';

import { Loader } from './Loader';

import Canvas, { Image as CanvasImage } from 'react-native-canvas';

import { PRIMARY_QUOTE_FONT, SECONDARY_QUOTE_FONT } from '../config/config';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
  item: {
    borderRadius: 8,
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
    position: 'relative',
  },
  quote: {
    fontSize: 32,
    marginBottom: 40,
    paddingRight: 40,
    textAlign: 'left',
  },
  author: {
    fontSize: 16,
    paddingRight: 75,
    textAlign: 'left',
    color: colours.two,
    textTransform: 'uppercase',
  },
  shareButton: {
    alignItems: 'center',
    padding: 0,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
});

const text = 'This is a inspirational quote';
const author = 'John Doe';
const minFontSize = 30;
const maxFontSize = 60;
const padding = 50;
const lineHeight = 2;
const align = 'left';
const valign = 'top';
// const canvasWidth = 800;
// const canvasHeight = 600;
const backgroundColor = 'transparent';
const textColor = '#000';
const authorColor = '#FFF';
let authorFontSize = 32;
// supported fonts on android: https://www.skcript.com/svr/react-native-fonts
const fontFamily = PRIMARY_QUOTE_FONT;
const authorFontFamily = SECONDARY_QUOTE_FONT;
const searchPhrase = 'test';

const QuoteCanvasCard = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true);

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: `${props.quote} | quote by ${props.author}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Alert.alert(error.message);
    }
  };

  const canvasRef = useRef(null);

  function resolveImage() {
    return require('../assets/backgrounds/jay-mantri-TFyi0QOx08c-unsplash.jpg');
  }

  const getFontSize = async (context, canvasWidth) => {
    // Autoscale text
    let fontSize = maxFontSize;
    do {
      context.font = `${fontSize}px ${fontFamily}`;
      fontSize -= 10;
    } while (
      (await context.measureText(text)).width > canvasWidth - 2 * padding &&
      fontSize >= minFontSize
    );

    return Promise.resolve(fontSize);
  };

  const getTextLines = async (context, canvasWidth) => {
    // Break text into lines if it overflows the canvas
    const words = props.quote.split(' ');
    let line = '';
    const lines = [];
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      console.log('testLine', testLine);
      // console.log('context', context)
      const lineWidth = (await context.measureText(testLine)).width;
      console.log('lineWidth', lineWidth);
      if (lineWidth > canvasWidth - 2 * padding && i > 0) {
        lines.push(line);
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);

    return Promise.resolve(lines);
  };

  const renderCreditText = (context, canvasWidth, canvasHeight, text) => {
    context.fillStyle = '#000';
    context.font = `16px monospace`;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    context.fillText(text.toUpperCase(), canvasWidth / 2, canvasHeight - 50);
  };

  const renderGradientBackground = async (context, canvasWidth, canvasHeight) => {
    // Create gradient
    // const grd = await context.createLinearGradient(0, 0, canvasWidth - 100, 0);
    
    const topOffset = 50;
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2 - topOffset;
    const innerRadius = 0;
    const outerRadius = Math.min(canvasWidth, canvasHeight) / 2;
    
    const grd = await context.createRadialGradient(centerX, centerY, innerRadius, centerX, centerY, outerRadius);
    
    grd.addColorStop(0, '#22d48f');
    grd.addColorStop(0.9, '#008a79');
    grd.addColorStop(1, '#008a79');
    
    // Fill with gradient
    context.fillStyle = grd;
    context.fillRect(0, 0, canvasWidth, canvasHeight);

    return Promise.resolve();
  };

  const renderCanvasText = async (
    context,
    canvasWidth,
    canvasHeight,
    fontSize,
    lines
  ) => {
    let yAuthorPos = 0;

    console.log('fontSize', fontSize);

    console.log('lines', lines);

    // Calculate text height
    const textHeight = lines.length * fontSize * lineHeight + padding * 2;

    // Draw Quote on canvas
    context.fillStyle = textColor;
    context.textBaseline = 'top';
    const topOffset = 100;
    for (let i = 0; i < lines.length; i++) {
      let yPos;
      if (lines.length === 1) {
        // If there's only one line of text, center it vertically
        if (valign === 'middle') {
          yPos = canvasHeight / 2 - fontSize / 2;
        } else {
          yPos = padding + topOffset;
        }
      } else {
        // Otherwise, use the regular calculation
        if (valign === 'middle') {
          yPos =
            canvasHeight / 2 -
            textHeight / 2 +
            (i * (fontSize * lineHeight) + padding);
        } else {
          yPos = i * (fontSize * lineHeight) + padding + topOffset;
        }
      }

      // Calculate x position based on alignment
      let xPos;
      if (align === 'left') {
        xPos = padding;
      } else if (align === 'center') {
        const lineWidth = (await context.measureText(lines[i])).width;
        xPos = canvasWidth / 2 - lineWidth / 2;
      }

      yAuthorPos = yPos + fontSize * lineHeight;

      context.fillText(lines[i], xPos, yPos);
    }

    // Draw author on canvas
    if (props.author && author) {
      const authorXPos = padding;
      const authorYPos = yAuthorPos + 20;
      context.fillStyle = authorColor;
      context.font = `${fontSize}px ${authorFontFamily}`;
      context.textAlign = align;
      context.textBaseline = 'top';
      context.fillText(
        `— ${props.author.toUpperCase()}`,
        authorXPos,
        authorYPos
      );
    }

    // Resolve the promise when rendering is complete
    return Promise.resolve();
  };

  const renderCanvas = async (customWidth, customHeight) => {
    const canvas = canvasRef.current;

    if (canvas === null) {
      return;
    }

    const context = canvas.getContext('2d');

    const { width, height } = Dimensions.get('window');

    console.log('screen width', width);
    console.log('screen height', height);

    const canvasWidth = width || customWidth;
    const canvasHeight = height || customHeight;

    // Set canvas dimensions
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Draw image background
    const imageUri =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII';

    if (Platform.OS !== 'web') {
      const image = new CanvasImage(canvas);

      // const imageUri = RNimage.resolveAssetSource(resolveImage()).uri;
      image.addEventListener('load', async () => {
        const fontSize = await getFontSize(context, canvasWidth);
        const lines = await getTextLines(context, canvasWidth);
        context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

        await renderGradientBackground(context, canvasWidth, canvasHeight);

        await renderCanvasText(
          context,
          canvasWidth,
          canvasHeight,
          fontSize,
          lines
        );

        renderCreditText(
          context,
          canvasWidth,
          canvasHeight,
          'gratefultoday.com'
        );

        setLoading(false);
        return Promise.resolve();
      });

      image.addEventListener('error', (error) => {
        console.log('image error', error);
      });

      image.src = imageUri;
    }

    if (Platform.OS === 'web') {
      // Draw the image on the canvas
      const image = document.createElement('img');
      image.src = imageUri;
      const fontSize = await getFontSize(context, canvasWidth);
      const lines = await getTextLines(context, canvasWidth);
      context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

      await renderGradientBackground(context, canvasWidth, canvasHeight);

      await renderCanvasText(
        context,
        canvasWidth,
        canvasHeight,
        fontSize,
        lines
      );

      renderCreditText(context, canvasWidth, canvasHeight, 'gratefultoday.com');

      setLoading(false);
      return Promise.resolve();
    }
  };

  useEffect(() => {
    renderCanvas();
  }, [
    text,
    author,
    minFontSize,
    maxFontSize,
    padding,
    lineHeight,
    align,
    valign,
    backgroundColor,
    textColor,
    authorColor,
    authorFontSize,
    fontFamily,
  ]);

  useImperativeHandle(ref, () => ({
    getSvgBase64: () => {
      return new Promise(async (resolve, reject) => {
        console.log('START 1');
        try {
          console.log('canvasRef.current', canvasRef.current);
          // canvasRef.current.width = 100;
          // canvasRef.current.height = 100;
          const base64 = await canvasRef.current.toDataURL();
          const base64String = base64.split(',')[1];
          resolve(base64String);
        } catch (error) {
          reject(error);
          console.error('useImperativeHandle getSvgBase64 error', error);
        }
      });
    },
  }));

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {loading && <Loader />}
      {Platform.OS !== 'web' && (
        <Canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: loading ? 0 : '100%',
            backgroundColor: 'transparent',
          }}
        />
      )}
      {Platform.OS === 'web' && (
        <canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: loading ? 0 : '100%',
            backgroundColor: 'transparent',
          }}
        />
      )}
    </SafeAreaView>
  );
});

export default QuoteCanvasCard;
