import React, { useState } from 'react';

import {
  View,
  useWindowDimensions,
  TextInput,
  SafeAreaView,
} from 'react-native';

import { TabView, TabBar } from 'react-native-tab-view';

import { styles, colours } from '../styles/main';

import {
  TAB_BAR_LABEL_FONT,
  TAB_VIEW_INDICATOR_STYLE,
  TAB_VIEW_STYLE,
  TAB_VIEW_PAGER_STYLE,
  DEFAULT_THANK_YOU_CARD_TITLE,
  DEFAULT_THANK_YOU_CARD_TEXT
} from '../config/config';

import ThankYouCanvasCard from '../components/ThankYouCanvasCard';

const EditCardRoute = ({ title, message, setTitle, setMessage }) => {
  return (
    <View style={{ ...styles.container, backgroundColor: colours.lighterGrey }}>
      <SafeAreaView>
        <TextInput
          label='Title'
          defaultValue={title}
          placeholder='Title'
          style={styles.input}
          onChangeText={(value) => setTitle(value)}
        />
        <TextInput
          label='Message'
          defaultValue={message}
          style={styles.textarea}
          placeholder='Message'
          onChangeText={setMessage}
          multiline
        />
      </SafeAreaView>
    </View>
  );
};

export const ThankyouCardScreen = ({ navigation, route }) => {
  const [index, setIndex] = React.useState(0);
  const [title, setTitle] = useState(DEFAULT_THANK_YOU_CARD_TITLE);
  const [message, setMessage] = useState(DEFAULT_THANK_YOU_CARD_TEXT);

  const layout = useWindowDimensions();

  const onTabChange = (index) => {
    setIndex(index);
  };

  const CardRoute = ({ title, message }) => {
    return (
      <View style={{ flex: 1, backgroundColor: '#673ab7' }}>
        <ThankYouCanvasCard
          quote='this is a test'
          title={title}
          message={message}
        />
      </View>
    );
  };

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'card':
        return <CardRoute title={title} message={message} />;
      case 'edit':
        return (
          <EditCardRoute
            title={title}
            message={message}
            setTitle={setTitle}
            setMessage={setMessage}
          />
        );
      default:
        return null;
    }
  };

  const [routes] = React.useState([
    { key: 'card', title: 'View' },
    { key: 'edit', title: 'Edit' },
  ]);

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: colours.yellow }}
      indicatorContainerStyle={{ backgroundColor: colours.three }}
      labelStyle={{
        color: colours.yellow,
        fontFamily: TAB_BAR_LABEL_FONT,
        letterSpacing: 1,
      }}
    />
  );

  return (
    <TabView
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={(index) => onTabChange(index)}
      initialLayout={{ width: layout.width }}
      indicatorStyle={{
        color: 'pink',
        backgroundColor: TAB_VIEW_INDICATOR_STYLE,
      }}
      style={{ color: 'pink', backgroundColor: TAB_VIEW_STYLE }}
      pagerStyle={{ color: 'pink', backgroundColor: TAB_VIEW_PAGER_STYLE }}
      tabBarPosition='top'
    />
  );
};
