import React, { useRef, useEffect } from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableHighlight,
  Image,
  Share,
} from 'react-native';

import Ionicons from '@expo/vector-icons/Ionicons';

import { colours } from '../styles/main';

import { StatusBar } from 'expo-status-bar';

import { PRIMARY_QUOTE_FONT, SECONDARY_QUOTE_FONT } from '../config/config';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
  item: {
    borderRadius: 8,
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
    position: 'relative',
  },
  quote: {
    fontSize: 32,
    fontFamily: PRIMARY_QUOTE_FONT,
    marginBottom: 40,
    paddingRight: 40,
    textAlign: 'left',
  },
  author: {
    fontSize: 16,
    fontFamily: SECONDARY_QUOTE_FONT,
    paddingRight: 75,
    textAlign: 'left',
    color: colours.two,
    textTransform: 'uppercase'
  },
  shareButton: {
    alignItems: 'center',
    padding: 0,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  mark: {
    backgroundColor: colours.yellow
  },
});

export const QuoteCard = (props) => {
  const onShare = async () => {
    console.log('onShare');
    try {
      const result = await Share.share({
        message:
          `${props.quote} | quote by ${props.author}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Alert.alert(error.message);
    }
  };

  const searchPhrases = props.searchPhrase.split(' ').filter(Boolean);

  const regex = new RegExp(`(${searchPhrases.join('|')})`, 'gi');

  const highlightedQuote = props.quote.split(regex).map((word, index) => {
    if (searchPhrases.includes(word.toLowerCase())) {
      return <Text style={styles.mark} key={index}>{word}</Text>;
    }
    return word;
  });

  let highlightedAuthor;
  if (props.author) {
    highlightedAuthor = props.author.split(regex).map((word, index) => {
      if (searchPhrases.includes(word.toLowerCase())) {
        return <Text style={styles.mark} key={index}>{word}</Text>;
      }
      return word;
    });
  }

  return (
    <View style={{ ...styles.item, backgroundColor: props.backgroundColor}}>
      <Text style={styles.quote}>{highlightedQuote}</Text>
      {highlightedAuthor && (<Text style={styles.author}>By {highlightedAuthor}</Text> )}
      <TouchableHighlight onPress={onShare}>
        <View style={styles.shareButton}>
          <Ionicons name='arrow-forward-outline' size={32} color={colours.three} />
        </View>
      </TouchableHighlight>
    </View>
  );
};
